import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Col,
  Nav,
  Navbar,
  Dropdown,
  DropdownMenu,
  NavDropdown,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";

import UnidadeDropdown from "../../components/Dropdowns/UnidadeDropdown";
import HospitalDropdown from "../../components/Dropdowns/HospitalDropdown";
import ProfileDropdown from "../../components/Dropdowns/ProfileDropdown";

import Colors from "../../styles/Colors";
import "../../styles/global.css";

import api from "../../services/api";
import Icon from "../../functions/Icon";
import logo from "../../assets/img/logo.png";
import Notify_Color from "../../assets/img/Notify_Color.svg";
import DecSense_Color from "../../assets/img/DecSense_Color.svg";

import { useDispatch, useSelector } from "react-redux";
import ModalHelpContact from "../Modals/ModalHelpContact";

function MainNavbar(props) {
  let timeout = null;
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const woundState = useSelector((state) => state.defaultWoundState);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { screen } = props;

  const isAllowedDomain =
    window.location.hostname === "master.preveni.com.br" ||
    window.location.hostname === "painel.preveni.com.br" ||
    window.location.hostname === "localhost";

  // Navbar
  const [current, setCurrent] = useState(props.history.location.pathname);
  const [hovered, setHovered] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  // Dropdowns
  const [dropPacientes, setDropPacientes] = useState(false);
  const [dropDescubra, setDropDescubra] = useState(false);

  // Subdropdowns
  const [dropHospital, setDropHospital] = useState(false);
  const [dropComunidade, setDropComunidade] = useState(false);
  const [dropParticipe, setDropParticipe] = useState(false);
  const [dropMais, setDropMais] = useState(false);

  useEffect(() => {
    function loadHospitalWoundState() {
      api
        .get("default-settings/hospitals/wound-state")
        .then((response) => {
          if (response.status == 200) {
            dispatch({
              type: "SET_DEFAULT_WOUND_STATE",
              selected: response.data.is_enable ? true : false,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: "SET_DEFAULT_WOUND_STATE",
            selected: false,
          });
        });
    }
    loadHospitalWoundState();
  }, []);

  // Atualiza menus
  useEffect(() => {
    if (hovered == "/Pacientes") {
      setDropDescubra(false);
      setDropHospital(false);
      setDropComunidade(false);
      setDropParticipe(false);
      setDropMais(false);
    } else if (hovered == "/Descubra") {
      setDropPacientes(false);
      setDropHospital(false);
      setDropComunidade(false);
      setDropParticipe(false);
      setDropMais(false);
    } else if (hovered == "DropComunidade") {
      setDropDescubra(true);
      setDropPacientes(false);
      setDropHospital(false);
      setDropParticipe(false);
      setDropHospital(false);
      setDropMais(false);
    } else if (hovered == "DropParticipe") {
      setDropDescubra(true);
      setDropPacientes(false);
      setDropComunidade(false);
      setDropHospital(false);
      setDropMais(false);
    } else if (hovered == "DropHospital") {
      setDropDescubra(true);
      setDropPacientes(false);
      setDropComunidade(false);
      setDropParticipe(false);
      setDropMais(false);
    } else if (hovered == "DropMais") {
      setDropDescubra(true);
      setDropPacientes(false);
      setDropComunidade(false);
      setDropParticipe(false);
      setDropMais(false);
    } else {
      setDropDescubra(false);
      setDropPacientes(false);
      setDropHospital(false);
      setDropComunidade(false);
      setDropParticipe(false);
      setDropMais(false);
    }
  }, [hovered]);

  // Atualiza página atual
  useEffect(() => {
    setCurrent(props.history.location.pathname);
  }, [props.history.location.pathname]);

  useEffect(() => {
    if (location.pathname === "/MapaDecubito") {
      return;
    }
    restartAutoReset();
    window.addEventListener("mousemove", restartAutoReset);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener("mousemove", restartAutoReset);
      }
    };
  }, [location.pathname]);

  //useEffect(() => {}, [location.pathname]);

  // Mudança no tamanho da janela
  useLayoutEffect(() => {
    function update() {
      setExpanded(false);
      setWindowSize([window.innerWidth, window.innerHeight]);
      setMobile(windowSize[0] < 768);
    }
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);

  useEffect(() => {
    setMobile(windowSize[0] < 768);
  }, windowSize);

  const restartAutoReset = () => {
    if (hospitalPlan.dados.type !== 2) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        history.push("/MapaDecubito");
      }, 300000);
    }
  };

  function Logout() {
    localStorage.clear();
    props.history.push({ pathname: "/" });
  }

  return (
    <Navbar
      expand="md"
      onToggle={() => setExpanded(!expanded)}
      className="main-nav bg-white justify-content-center w-100 px-0"
    >
      <Navbar.Brand href="/Home" className="pr-0 ml-4 mr-auto brand-col">
        <img src={logo} className="logo-nav" />
      </Navbar.Brand>
      {mobile && <UnidadeDropdown screen={screen} />}

      <Navbar.Toggle
        className="navbar-toggle mr-4"
        onClick={() => setExpanded(!expanded)}
      />

      {!mobile && (
        <>
          <Navbar.Collapse
            navbar
            className={
              expanded
                ? "open-div pl-0 mx-0 w-100"
                : "pl-0 w-100 justify-content-center"
            }
          >
            <Nav>
              <Link
                onClick={() => props.history.push({ pathname: "/Home" })}
                onMouseEnter={() => setHovered("/Home")}
                onMouseLeave={() => setHovered(null)}
                className={`${expanded ? "mx-0" : ""} nav-link`}
                to={{
                  pathname: "/Home",
                }}
              >
                <Icon
                  color={
                    current == "/Home"
                      ? Colors.brand_blue
                      : hovered == "/Home"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="home"
                  className="mr-2"
                />
                <p
                  style={{
                    color:
                      current == "/Home"
                        ? Colors.brand_blue
                        : hovered == "/Home"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Home
                </p>
              </Link>

              <Link
                onMouseEnter={() => {
                  setHovered("/Pacientes");
                  setDropPacientes(true);
                }}
                className={`${expanded ? "mx-0" : ""} nav-link`}
                to={{
                  pathname: "/Pacientes",
                }}
              >
                <Dropdown className="dropdown-btn">
                  <Icon
                    color={
                      current == "/Pacientes"
                        ? Colors.brand_blue
                        : hovered == "/Pacientes"
                        ? Colors.darker_gray
                        : Colors.brand_gray
                    }
                    size={22}
                    icon="user-group"
                    className="mr-2"
                  />
                  <p
                    style={{
                      color:
                        current == "/Pacientes"
                          ? Colors.brand_blue
                          : hovered == "/Pacientes"
                          ? Colors.darker_gray
                          : Colors.brand_gray,
                    }}
                  >
                    Pacientes
                  </p>
                  <Icon
                    color={
                      current == "/Pacientes"
                        ? Colors.brand_blue
                        : hovered == "/Pacientes"
                        ? Colors.darker_gray
                        : Colors.brand_gray
                    }
                    size={18}
                    icon="arrow-select"
                    className="ml-2"
                  />

                  <Dropdown.Menu
                    className="main-nav-drop"
                    onMouseEnter={() => {
                      setHovered("/Pacientes");
                      setDropPacientes(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(null);
                      setDropPacientes(false);
                    }}
                    show={dropPacientes}
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.history.push({
                          pathname: "/Pacientes",
                          state: { addPatient: true },
                        });
                      }}
                    >
                      Adicionar paciente
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch({
                          type: "SET_TAB_PATIENT_PAGE",
                          selected: "ativos",
                        });
                        props.history.push({
                          pathname: "/Pacientes",
                          state: { discharge: false },
                        });
                      }}
                    >
                      Pacientes internados
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch({
                          type: "SET_TAB_PATIENT_PAGE",
                          selected: "alta",
                        });
                        props.history.push({
                          pathname: "/Pacientes",
                          state: { discharge: true },
                        });
                      }}
                    >
                      Pacientes de alta
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Link>

              {/* <Nav.Link
                onClick={() => props.history.push({ pathname: "/Descubra" })}
                onMouseEnter={() => {
                  setHovered("/Descubra");
                  setDropDescubra(true);
                }}
                className={expanded ? "mx-0" : ""}
              >
                <Dropdown className="dropdown-btn">
                  <Icon
                    color={
                      current == "/Descubra"
                        ? Colors.brand_blue
                        : hovered == "/Descubra"
                        ? Colors.darker_gray
                        : Colors.brand_gray
                    }
                    size={22}
                    icon="search"
                    className="mr-2"
                  />
                  <a
                    href="/Descubra"
                    style={{
                      color:
                        current == "/Descubra"
                          ? Colors.brand_blue
                          : hovered == "/Descubra"
                          ? Colors.darker_gray
                          : Colors.brand_gray,
                    }}
                  >
                    Descubra
                  </a>
                  <Icon
                    color={
                      current == "/Descubra"
                        ? Colors.brand_blue
                        : hovered == "/Descubra"
                        ? Colors.darker_gray
                        : Colors.brand_gray
                    }
                    size={18}
                    icon="arrow-select"
                    className="ml-2"
                  />

                  <Dropdown.Menu
                    className="main-nav-drop"
                    onMouseEnter={() => {
                      setHovered("/Descubra");
                      setDropDescubra(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(null);
                      setDropDescubra(false);
                    }}
                    show={dropDescubra}
                  >
                    <Dropdown.Item
                      href="/"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      onMouseEnter={() => {
                        setHovered("DropHospital");
                        setDropHospital(true);
                      }}
                    >
                      <div className="col ml-0 pl-0">Hospital</div>
                      <div className="col ml-2 mr-0 pr-0 align-items-end">
                        <Icon
                          color={Colors.darker_gray}
                          size={20}
                          icon="arrow-select"
                          className="side-arrow ml-2"
                        />
                      </div>
                        <Dropdown
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          drop={"right"}>
                          <Dropdown.Menu
                            className="main-nav-subdrop"
                            show={dropHospital}
                            onMouseEnter={() => {
                              setHovered("DropHospital");
                              setDropHospital(true);
                            }}
                            onMouseLeave={() => {
                              setHovered(null);
                              setDropHospital(false);
                            }}
                          >
                            <Dropdown.Item href="/Descubra/Comunicados">
                              Comunicados
                            </Dropdown.Item>
                            <Dropdown.Item href="/Descubra/Protocolos">Protocolos</Dropdown.Item>
                            <Dropdown.Item href="/Descubra/Bundles">Bundles</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      onMouseEnter={() => {
                        setHovered("DropComunidade");
                        setDropComunidade(true);
                      }}
                    >
                      Comunidade
                      <Icon
                        color={Colors.darker_gray}
                        size={20}
                        icon="arrow-select"
                        className="side-arrow ml-2"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      onMouseEnter={() => {
                        setHovered("DropParticipe");
                        setDropParticipe(true);
                      }}
                    >
                      Participe
                      <Icon
                        color={Colors.darker_gray}
                        size={20}
                        icon="arrow-select"
                        className="side-arrow ml-2"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onMouseEnter={() => {
                        setDropMais(true);
                      }}
                    >
                      <Dropdown
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        drop={"right"}
                      >
                        Saiba mais
                        <Icon
                          color={Colors.darker_gray}
                          size={20}
                          icon="arrow-select"
                          className="side-arrow ml-2"
                        />
                        <Dropdown.Menu
                          className="main-nav-subdrop"
                          onMouseEnter={() => {
                            setDropMais(true);
                          }}
                          onMouseLeave={() => {
                            setDropMais(false);
                          }}
                          show={dropMais}
                        >
                          <Dropdown.Item>Notícias</Dropdown.Item>
                          <Dropdown.Item>Artigos</Dropdown.Item>
                          <Dropdown.Item>Leis</Dropdown.Item>
                          <Dropdown.Item>Notas técnicas</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link> */}

              {hospitalPlan.dados.type === 1 && (
                <Link
                  onMouseEnter={() => setHovered("/MapaDecubito")}
                  onMouseLeave={() => setHovered(null)}
                  className={`${expanded ? "mx-0" : ""} nav-link`}
                  to={{
                    pathname: "/MapaDecubito",
                  }}
                >
                  <Icon
                    color={
                      current == "/MapaDecubito"
                        ? Colors.brand_blue
                        : hovered == "/MapaDecubito"
                        ? Colors.darker_gray
                        : Colors.brand_gray
                    }
                    size={22}
                    icon="refresh"
                    className="mr-2"
                  />
                  <p
                    style={{
                      color:
                        current == "/MapaDecubito"
                          ? Colors.brand_blue
                          : hovered == "/MapaDecubito"
                          ? Colors.darker_gray
                          : Colors.brand_gray,
                    }}
                  >
                    Mapa de decúbito
                  </p>
                </Link>
              )}

              <Link
                onMouseEnter={() => setHovered("/Relatorios")}
                onMouseLeave={() => setHovered(null)}
                className={`${expanded ? "mx-0" : ""} nav-link`}
                to={{
                  pathname: "/Relatorios",
                }}
              >
                <Icon
                  color={
                    current == "/Relatorios"
                      ? Colors.brand_blue
                      : hovered == "/Relatorios"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="pizza-chart"
                  className="mr-2"
                />
                <p
                  style={{
                    color:
                      current == "/Relatorios"
                        ? Colors.brand_blue
                        : hovered == "/Relatorios"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Relatórios
                </p>
              </Link>

              {/* <Nav.Link
                onMouseEnter={() => setHovered("/Aprenda")}
                onMouseLeave={() => setHovered(null)}
                className={expanded ? "mx-0" : ""}
              >
                <Icon
                  color={
                    current == "/Aprenda"
                      ? Colors.brand_blue
                      : hovered == "/Aprenda"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="book-open"
                  className="mr-2"
                />
                <a
                  href="#"
                  style={{
                    color:
                      current == "/Aprenda"
                        ? Colors.brand_blue
                        : hovered == "/Aprenda"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Aprenda
                </a>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse
            navbar
            className={expanded ? "mx-0 open-div" : "ml-auto mr-0"}
          >
            <Nav className="align-items-center">
              <div>
                <Navbar.Collapse>
                  <button
                    className="button-help-contact"
                    onClick={() => {
                      setShowHelpModal(true);
                    }}
                  >
                    <Icon icon="help-contact" size={24} />
                    <p className="help-contact-text ml-3">Ajuda</p>
                  </button>
                </Navbar.Collapse>
              </div>
              {user?.dados?.is_super_user == true && isAllowedDomain && (
                <HospitalDropdown history={props.history} screen={screen} />
              )}
              <UnidadeDropdown history={props.history} screen={screen} />
              <ProfileDropdown history={props.history} />
            </Nav>
          </Navbar.Collapse>
        </>
      )}

      {mobile && (
        <>
          <Navbar.Collapse navbar className="open-div pl-0 mx-0 w-100">
            <Nav>
              <Nav.Link
                onClick={() => props.history.push({ pathname: "/Home" })}
                onMouseEnter={() => setHovered("/Home")}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/Home"
                      ? Colors.brand_blue
                      : hovered == "/Home"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="home"
                  className="mr-2"
                />
                <a
                  href="/Home"
                  style={{
                    color:
                      current == "/Home"
                        ? Colors.brand_blue
                        : hovered == "/Home"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Home
                </a>
              </Nav.Link>

              <Nav.Link
                onClick={() => props.history.push({ pathname: "/Pacientes" })}
                onMouseEnter={() => {
                  setHovered("/Pacientes");
                }}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/Pacientes"
                      ? Colors.brand_blue
                      : hovered == "/Pacientes"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="user-group"
                  className="mr-2"
                />
                <a
                  href="/Pacientes"
                  style={{
                    color:
                      current == "/Pacientes"
                        ? Colors.brand_blue
                        : hovered == "/Pacientes"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Pacientes
                </a>
              </Nav.Link>

              {/* <Nav.Link
                onClick={() => props.history.push({ pathname: "/Descubra" })}
                onMouseEnter={() => {
                  setHovered("/Descubra");
                }}
              >
                <Icon
                  color={
                    current == "/Descubra"
                      ? Colors.brand_blue
                      : hovered == "/Descubra"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="search"
                  className="mr-2"
                />
                <a
                  href="/Descubra"
                  style={{
                    color:
                      current == "/Descubra"
                        ? Colors.brand_blue
                        : hovered == "/Descubra"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Descubra
                </a>
              </Nav.Link> */}

              <Nav.Link
                onClick={() =>
                  props.history.push({ pathname: "/MapaDecubito" })
                }
                onMouseEnter={() => {
                  setHovered("/Pacientes");
                }}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/MapaDecubito"
                      ? Colors.brand_blue
                      : hovered == "/MapaDecubito"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="refresh"
                  className="mr-2"
                />
                <a
                  href="/MapaDecubito"
                  style={{
                    color:
                      current == "/MapaDecubito"
                        ? Colors.brand_blue
                        : hovered == "/MapaDecubito"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Mapa de decúbito
                </a>
              </Nav.Link>

              <Nav.Link
                onMouseEnter={() => setHovered("/Relatorios")}
                onMouseLeave={() => setHovered(null)}
                onClick={(e) => {
                  e.stopPropagation();
                  props.history.push({ pathname: "/Relatorios" });
                }}
              >
                <Icon
                  color={
                    current == "/Relatorios"
                      ? Colors.brand_blue
                      : hovered == "/Relatorios"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="pizza-chart"
                  className="mr-2"
                />
                <a
                  href="#"
                  style={{
                    color:
                      current == "/Relatorios"
                        ? Colors.brand_blue
                        : hovered == "/Relatorios"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Relatórios
                </a>
              </Nav.Link>

              {/* <Nav.Link
                onMouseEnter={() => setHovered("/Aprenda")}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/Aprenda"
                      ? Colors.brand_blue
                      : hovered == "/Aprenda"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="book-open"
                  className="mr-2"
                />
                <a
                  href="#"
                  style={{
                    color:
                      current == "/Aprenda"
                        ? Colors.brand_blue
                        : hovered == "/Aprenda"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Aprenda
                </a>
              </Nav.Link> */}

              <Nav.Link
                onMouseEnter={() => setHovered("/MinhaConta")}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/MinhaConta"
                      ? Colors.brand_blue
                      : hovered == "/MinhaConta"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="settings"
                  className="mr-2"
                />
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    props.history.push({ pathname: "/MinhaConta" });
                  }}
                  style={{
                    color:
                      current == "/MinhaConta"
                        ? Colors.brand_blue
                        : hovered == "/MinhaConta"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Configurações
                </a>
              </Nav.Link>

              <NavDropdown.Divider />
              <Nav.Link
                onMouseEnter={() => setHovered("/")}
                onMouseLeave={() => setHovered(null)}
              >
                <Icon
                  color={
                    current == "/"
                      ? Colors.brand_blue
                      : hovered == "/"
                      ? Colors.darker_gray
                      : Colors.brand_gray
                  }
                  size={22}
                  icon="help-contact"
                  className="mr-2"
                />
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowHelpModal(true);
                  }}
                  style={{
                    color:
                      current == "/"
                        ? Colors.brand_blue
                        : hovered == "/"
                        ? Colors.darker_gray
                        : Colors.brand_gray,
                  }}
                >
                  Ajuda
                </a>
              </Nav.Link>

              <Nav.Link onClick={(e) => Logout()}>
                <Icon
                  color={Colors.brand_gray}
                  size={22}
                  icon="logout"
                  className="mr-2"
                />
                <a
                  style={{
                    color: Colors.brand_gray,
                  }}
                >
                  Sair
                </a>
              </Nav.Link>

              {user?.dados?.is_super_user == true && isAllowedDomain && (
                <HospitalDropdown history={props.history} screen={screen} />
              )}
            </Nav>
          </Navbar.Collapse>
        </>
      )}
      <ModalHelpContact
        show_modal={showHelpModal}
        handler_modal={setShowHelpModal}
      />
    </Navbar>
  );
}

export default MainNavbar;
