import React, { useState } from "react";
import { Col, Row, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";

function ModalEnableDisable({ ...props }) {
  const dispatch = useDispatch();
  const {
    handler_show_modal,
    show_modal,
    handler_reload,
    reload,
    hospitalObj,
  } = props;

  const [loadingBtn, setLoadingBtn] = useState("none");
  const currentHospital = useSelector((state) => state.hospital?.selecionado);

  function Save() {
    if (hospitalObj.id === 1 && hospitalObj.status === 1) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Não é possível desativar o hospital padrão.",
        })
      );
      handler_show_modal(false);
      return;
    }

    if (hospitalObj.id === currentHospital?.id && hospitalObj.status === 1) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Não é possível desativar o hospital em que você está.",
        })
      );
      handler_show_modal(false);
      return;
    }

    setLoadingBtn("flex");

    api
      .put(`/hospitals/${hospitalObj.id}`, {
        ...hospitalObj,
        status: hospitalObj.status === 1 ? 0 : 1,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: `O hospital foi ${
                hospitalObj.status === 1 ? "desativado" : "ativado"
              } com sucesso!`,
            })
          );
          handler_reload(!reload);
          dispatch({ type: "UPDATE_HOSPITAL_LIST" });
        }
      })
      .catch((error) => {
        console.error("Save error", error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: `Não foi possível ${
              hospitalObj.status === 1 ? "desativar" : "ativar"
            } este hospital`,
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
    >
      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={8}>
            <div className="modal-title">
              {hospitalObj.status === 1 ? "Desativar" : "Ativar"} hospital
            </div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="mt-4">
          <p className="text-delete-user">
            O hospital <span>{hospitalObj.name}</span> será{" "}
            {hospitalObj.status === 1 ? "desativado" : "ativado"}. Tem certeza
            que deseja continuar?
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            style={{ borderStyle: "solid" }}
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => handler_show_modal(false)}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={loadingBtn === "flex"}
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor: Colors.brand_pink,
            }}
            onClick={() => Save()}
          >
            <p
              className="mb-0"
              style={{
                color: Colors.white,
                display: loadingBtn === "none" ? "flex" : "none",
              }}
            >
              {hospitalObj.status === 1 ? "Desativar" : "Ativar"}
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEnableDisable;
