import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { NormalizeWord, VerifyMatchesText } from "../../functions/utils";
import { Show_Alert } from "../../store/actions/alert";

var hospitalSelect = null;
var listHospitalsOriginal = [];

function HospitalDropdown(props) {
  const { history, screen } = props;
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.hospital) || {
    selecionado: { id: 1, name: "Hospital Padrão", status: 1 },
  };
  const updateHospital = useSelector((state) => state.updateHospital);

  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [hospitais, setHospitais] = useState([]);

  useEffect(() => {
    Search("");
  }, [show]);

  async function SetarHospital(item) {
    try {
      await api.put("/users/hospital/change", {
        hospitalId: item.id,
      });

      setQuery("");
      Search("");
      dispatch({ type: "SET_HOSPITAL", selected: item });

      if (screen === "patient-profile") {
        history.push("/pacientes");
      }

      window.location.reload();
    } catch (error) {
      console.error("Erro ao atualizar hospital do usuário:", error);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Erro ao atualizar hospital. Tente novamente.",
        })
      );
    }
  }

  useEffect(() => {
    hospitalSelect = hospital?.selecionado?.id || 1;
  }, []);

  useEffect(() => {
    loadHospitais();
  }, [updateHospital]);

  const loadHospitais = async () => {
    try {
      const response = await api.get("/hospitals");
      const listHospitals = response.data.filter(
        (h) => h.name !== null && h.status === 1
      );
      console.log("Lista de hospitais atualizada:", listHospitals);

      setHospitais(listHospitals);
      listHospitalsOriginal = listHospitals;

      const currentHospitalExists = listHospitals.find(
        (h) => h.id === hospital?.selecionado?.id
      );
      if (!currentHospitalExists && listHospitals.length > 0) {
        console.log(
          "Hospital atual não encontrado, selecionando primeiro da lista"
        );
        dispatch({
          type: "SET_HOSPITAL",
          selected: listHospitals[0],
        });
      }

      if (!hospital?.selecionado?.id && listHospitals.length > 0) {
        dispatch({
          type: "SET_HOSPITAL",
          selected: listHospitals[0],
        });
      }

      if (listHospitals.length === 0) {
        const defaultHospital = {
          id: 1,
          name: "Hospital Padrão",
          status: 1,
        };
        setHospitais([defaultHospital]);
        listHospitalsOriginal = [defaultHospital];
        dispatch({
          type: "SET_HOSPITAL",
          selected: defaultHospital,
        });
      }
    } catch (error) {
      console.error("Erro ao carregar hospitais:", error);
      const defaultHospital = {
        id: 1,
        name: "Hospital Padrão",
        status: 1,
      };
      setHospitais([defaultHospital]);
      listHospitalsOriginal = [defaultHospital];
      dispatch({
        type: "SET_HOSPITAL",
        selected: defaultHospital,
      });
    }
  };

  const Search = (word) => {
    setQuery(word);

    if (listHospitalsOriginal.length === 0) listHospitalsOriginal = hospitais;

    if (word === "") {
      setHospitais(listHospitalsOriginal);
      return false;
    }

    var newList = listHospitalsOriginal.filter((item) => {
      const normalize = NormalizeWord(item.name);
      const normalizeQuery = NormalizeWord(word);
      if (normalize.includes(normalizeQuery)) return item;
    });

    setHospitais(newList);
  };

  return (
    <Dropdown
      className="drop-sort px-3"
      onToggle={() => setShow(!show)}
      data-testid="navbar-hospital"
    >
      <Dropdown.Toggle
        id="dropdown-hospital"
        className={`btn-unidade ${props.cssClasses}`}
        style={props.cssStyles}
        data-testid="navbar-hospital-toggle"
      >
        <div className="mr-3">
          <p className="limit_word" style={{ width: 100 }}>
            {hospital?.selecionado?.name || "Hospital Padrão"}
          </p>
        </div>
        <Icon color={Colors.darker_gray} size={20} icon="arrow-select" />
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        <div className="my-2">
          <input
            style={{ height: 42 }}
            className="default-input"
            type="text"
            placeholder="Pesquisar"
            value={query}
            onChange={(e) => Search(e.target.value)}
          />
        </div>

        <div className="container-auto-units">
          {hospitais.length === 0 ? (
            <p className="no-unit">Nenhum resultado foi encontrado</p>
          ) : (
            hospitais.map((item, index) => (
              <Dropdown.Item
                key={index}
                className="drop-sort-item"
                onClick={() => SetarHospital(item)}
                data-testid="navbar-hospital-item"
              >
                <div
                  style={
                    hospital?.selecionado?.id === item.id
                      ? { color: Colors.brand_blue }
                      : {}
                  }
                >
                  <p>{item.name}</p>
                </div>
              </Dropdown.Item>
            ))
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default HospitalDropdown;
