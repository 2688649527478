import Period from "./Period";
import moment from "moment";
import Datasets from "./Datasets";

import { defineTimeInitialAndFinalBySchedules } from "./Utils";

const existPositionOnList = (position, list) => {
  const items = list.map(({ position }) => {
    return position;
  });

  return items.includes(position);
};

const getPositionIndexOnList = (position, list) => {
  let positionIndex = 0;

  list.forEach((item, index) => {
    if (item.position === position) positionIndex = index;
  });

  return positionIndex;
};

const getDisplayByTime = (value) => {
  const today = moment().format("YYYY-MM-DD 00:00:00");
  const time = moment(today, "YYYY-MM-DD HH:mm:ss").add(value, "seconds");

  return time.format("HH:mm:ss");
};

function calculateTimeDifference(initial, final) {
  return (new Date(final) - new Date(initial)) / 1000;
}

export default class DecubitWatchApp {
  constructor(props) {
    this.historics = (props && props.historics) || [];
    this.schedules = (props && props.schedules) || [];
    console.log("HISTORIC PROPS", this.historics);

    const period = (props && props.period) || "morning";
    const date = (props && props.date) || moment().format("YYYY-MM-DD");
    const { timeInitial, timeFinal } = defineTimeInitialAndFinalBySchedules(
      period,
      this.schedules
    );

    this.options = {
      period,
      date,
      timeInitial,
      timeFinal,
    };
    this.period = Period.bind(this)();
    this.datasets = Datasets.bind(this)();
  }

  getData() {
    console.log("getData_______________");
    const timer = this.datasets.timer();
    console.log("timer", timer);

    const positions = this.datasets.positions();
    console.log("positions", positions);

    const historic = this.datasets.historic();
    console.log("historic", historic);

    console.log("getData_______________");

    return this.datasets.json(timer, positions, historic);
  }

  getApp() {
    const json = JSON.stringify(this);
    return JSON.parse(json);
  }

  getOverview() {
    const results = [];
    const historics = this.period.getHistorics();

    if (historics.length > 0) {
      historics.forEach((historic) => {
        const { positionItem, time } = historic;
        let activeTime = time;

        const processSuspension = (suspensionName, suspensionTime) => {
          if (suspensionTime > 0) {
            if (existPositionOnList(suspensionName, results)) {
              const suspensionIndex = getPositionIndexOnList(
                suspensionName,
                results
              );
              const suspensionItem = results[suspensionIndex];
              suspensionItem.time += suspensionTime;
              suspensionItem.display = getDisplayByTime(suspensionItem.time);
              suspensionItem.color = "black";

              results[suspensionIndex] = suspensionItem;
            } else {
              results.push({
                position: suspensionName,
                time: suspensionTime,
                display: getDisplayByTime(suspensionTime),
                color: "black",
              });
            }
          }
        };

        if (
          historic.isPaused &&
          historic.suspensionItem &&
          historic.suspensionItem.initial &&
          historic.suspensionItem.final
        ) {
          const suspensionTime = calculateTimeDifference(
            historic.datetime_position_initial,
            historic.datetime_position_final
          );

          activeTime = time - suspensionTime;

          processSuspension("Pausado", suspensionTime);
        }

        if (
          !historic.isPaused &&
          historic.suspensionItem &&
          historic.suspensionItem.initial &&
          historic.suspensionItem.final
        ) {
          const suspensionTime = calculateTimeDifference(
            historic.datetime_position_initial,
            historic.datetime_position_final
          );

          activeTime = time - suspensionTime;

          processSuspension("Suspenso", suspensionTime);
        }

        if (activeTime > 0) {
          if (existPositionOnList(positionItem.name, results)) {
            const positionIndex = getPositionIndexOnList(
              positionItem.name,
              results
            );
            const historicItem = results[positionIndex];
            historicItem.time += activeTime;
            historicItem.display = getDisplayByTime(historicItem.time);

            results[positionIndex] = historicItem;
          } else {
            results.push({
              position: positionItem.name,
              time: activeTime,
              display: getDisplayByTime(activeTime),
            });
          }
        }
      });
    }

    return results;
  }
}
