import React, { useState, useEffect, useLayoutEffect, createRef } from "react";
import { Button, Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import PwStrengthMeter from "../../../components/PwStrengthMeter";
import CheckBox from "../../../components/CheckBox";
import ModalTermos from "../../../components/Modals/ModalTermos";
import ModalPolitica from "../../../components/Modals/ModalPolitica";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import Icon from "../../../functions/Icon";
import iconSet from "../../../assets/font/selection.json";
import { iconList } from "icomoon-react";

import logo from "../../../assets/img/logo.png";
import nursing from "../../../assets/img/nursing01-2.svg";

import api from "../../../services/api";
import { SaveToken } from "../../../functions/storage";
import { isAuthenticated } from "../../../functions/utils";
import { User_Login } from "../../../../src/store/actions/user";
import { useSelector, useDispatch } from "react-redux";
import { Show_Alert } from "../../../store/actions/alert";
import ReCAPTCHA from "react-google-recaptcha";

function Login({ history }) {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();

  // Exibição
  const [mobile, setMobile] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showModalTermos, setShowModalTermos] = useState(false);
  const [showModalPolitica, setShowModalPolitica] = useState(false);

  // Flags
  const [signInUp, setSignInUp] = useState("in");
  const [metodoLogin, setMetodoLogin] = useState("email");
  const [viewPwd, setViewPwd] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [aceito, setAceito] = useState(false);
  const [validLogin, setValidLogin] = useState(false);
  const [validCadastro, setValidCadastro] = useState(false);

  // Campos - Login
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");

  // Erros
  const [erroEmail, setErroEmail] = useState("");
  const [levelPwd, setLevelPwd] = useState(0);
  const [generalError, setGeneralError] = useState("");
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [loadingBtnRegister, setLoadingBtnRegister] = useState("none");

  // Verifica se usuário já está autenticado
  useEffect(() => {
    //Verifica se tem token salvo no local storage
    if (isAuthenticated()) {
      history.push({ pathname: "/Home" });
    }
  }, []);

  // Verifica validade dos campos preenchidos
  useEffect(() => {
    // Verificando email
    var validade_email = false;

    if (email.length == 0) {
      setErroEmail("Este campo é obrigatório");
    } else {
      validade_email = VerifyEmail(email);
      if (!validade_email) {
        setErroEmail("Por favor, insira um endereço de e-mail válido");
      } else {
        setErroEmail("");
      }
    }

    if (validade_email && senha.length > 0) {
      setValidLogin(true);
    } else {
      setValidLogin(false);
    }

    if (nome.length > 0 && validLogin && levelPwd >= 1 && aceito) {
      setValidCadastro(true);
    } else {
      setValidCadastro(false);
    }

    setSubmit(true);
  }, [email, senha, nome, levelPwd, aceito]);

  // Reseta status de submit quanto muda pra sign in ou sign up
  useEffect(() => {
    setSubmit(false);
  }, [signInUp]);

  function RunCaptcha() {
    if (!validLogin) {
      setSubmit(true);
      return false;
    }
    setLoadingBtn("flex");
    setGeneralError("");
    recaptchaRef.current.execute();
  }

  // Executa login
  function Login(recaptchaToken) {
    api
      .post("/login", {
        email,
        password: senha,
        recaptchaToken,
      })
      .then((response) => {
        if (response.status == 200 && response.data.user.status == 1) {
          console.log("Login success");
          console.log(response.data);

          SaveToken(response.data.token);

          var dados = {
            id: response.data.user.id,
            name: response.data.user.name,
            email: response.data.user.email,
            cpf: response.data.user.cpf,
            is_super_user: response.data.user.is_super_user,
            permission_id: response.data.user.permission_id,
            permission: response.data.user.permission,
            image: response.data.user.image,
            url_image: response.data.user.url_image,
            gender: response.data.user.gender,
            date_birth: response.data.user.date_birth,
            unit_id: response.data.user.unit_id,
            units: response.data.user.units,
            qualification_id: response.data.user.qualification_id,
            professional_record: response.data.user.professional_record,
            type_workplace_id: response.data.user.type_workplace_id,
            hospital_id: response.data.user.hospital_id,
            city: response.data.user.city,
            is_mfa_enabled: response.data.user.is_mfa_enabled,
            is_mfa_mandatory: response.data.user.is_mfa_mandatory,
          };
          const hospitalPlan = response.data.user.hospital?.plan?.planData;
          loadHospitalWoundState();
          dispatch(User_Login(dados));
          dispatch({
            type: "SET_HOSPITAL_PLAN",
            dados: hospitalPlan ? hospitalPlan : 1,
          });
          if (dados.is_mfa_mandatory && !dados.is_mfa_enabled) {
            history.push({ pathname: "/Otp/Cadastro" });
          } else if (dados.is_mfa_mandatory && dados.is_mfa_enabled) {
            history.push({ pathname: "/Otp/Validador" });
          } else {
            history.push({ pathname: "/Home" });
          }

          //Se ja tiver unidade_id
          //busca todas as unidades e salva local
          // if (response.data.user.units != null) {
          //   LoadUnits();
          // } else {
          //   history.push({ pathname: "/Home" });
          // }
        } else {
          setGeneralError("Sua conta foi desativada.");
        }
      })
      .catch((error) => {
        console.log("Login error " + error);
        if (error?.response?.data?.message) {
          setGeneralError(error.response.data.message);
        } else {
          setGeneralError(
            "Aconteceu um erro na autenticação, contate o suporte."
          );
        }
      })
      .finally(() => setLoadingBtn("none"));
  }

  function loadHospitalWoundState() {
    api
      .get("default-settings/hospitals/wound-state")
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: "SET_DEFAULT_WOUND_STATE",
            selected: response.data.is_enable ? true : false,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "SET_DEFAULT_WOUND_STATE",
          selected: false,
        });
      });
  }

  // Carrega unidades do usuário logado
  function LoadUnits() {
    api
      .get(`/users/units`)
      .then((response) => {
        if (response.status == 200) {
          dispatch({ type: "USER_UNIT_SET", list: response.data });
          dispatch({ type: "UNIT_SET", selecionado: response.data[0] });
        }
      })
      .catch((error) => {
        console.log("LoadUnits " + error);
      })
      .finally(() => history.push({ pathname: "/Home" }));
  }

  // Executa novo cadastro
  function Cadastro() {
    if (validCadastro) {
      setLoadingBtnRegister("flex");
      api
        .post("/register", {
          name: nome,
          email,
          password: senha,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            console.log("Cadastro success");
            console.log(response.data);

            if (
              response.data.token == undefined &&
              response.data[0].field == "email"
            ) {
              dispatch(
                Show_Alert({
                  type: "error",
                  msg: "Este e-mail já possui cadastro no sistema.",
                })
              );

              return false;
            }

            SaveToken(response.data.token);

            var dados = {
              id: response.data.user.id,
              name: response.data.user.name,
              email: response.data.user.email,
              cpf: response.data.user.cpf,
              permission_id: response.data.user.permission_id,
              permission: response.data.user.permission,
              image: response.data.user.image,
              url_image: response.data.user.url_image,
              gender: response.data.user.gender,
              date_birth: response.data.user.date_birth,
              unit_id: response.data.user.unit_id,
              units: response.data.user.units,
              qualification_id: response.data.user.qualification_id,
              professional_record: response.data.user.professional_record,
              type_workplace_id: response.data.user.type_workplace_id,
              hospital_id: response.data.user.hospital_id,
              city: response.data.user.city,
            };

            //Seta os dados do user no redux
            dispatch(User_Login(dados));

            setLoadingBtnRegister("none");

            dispatch(
              Show_Alert({
                type: "success",
                msg: "Parabéns! Cadastro realizado com sucesso.",
              })
            );

            history.push({ pathname: "/Home" });
          }
        })
        .catch((error) => {
          console.log("Cadastro error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível realizar o cadastro, tente novamente.",
            })
          );
        })
        .finally(() => setLoadingBtnRegister("none"));
    }
  }

  // Verifica validade do email
  function VerifyEmail() {
    if (email.length > 0) {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var res = new RegExp(regex).test(email);
      return res;
    } else {
      return false;
    }
  }

  // Muda de sign in pra sign up
  function changeSignInUp(opt) {
    setSignInUp(opt);
    setMetodoLogin("email");
    setNome("");
    setEmail("");
    setSenha("");
    setAceito(false);
    setValidCadastro(false);
    setValidLogin(false);
    setSubmit(false);
  }

  // Marcar checkbox de termos e política de privacidade
  function checkAceito() {
    setAceito(!aceito);
  }

  // Tamanho da janela
  useLayoutEffect(() => {
    function update() {
      setWindowSize([window.innerWidth, window.innerHeight]);
      setMobile(windowSize[0] < 791);
    }
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);

  useEffect(() => {
    setMobile(windowSize[0] < 791);
  }, windowSize);

  return (
    <Container className="login" fluid style={{ minHeight: "100vh" }}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={Login}
      />
      {showModalTermos && (
        <ModalTermos handler={setShowModalTermos} show={showModalTermos} />
      )}
      {showModalPolitica && (
        <ModalPolitica
          handler={setShowModalPolitica}
          show={showModalPolitica}
        />
      )}

      <div className="row-login py-3 py-lg-0">
        <div className="col-login col-left">
          <div className="content h-100 mb-0">
            <img src={logo} className="logo img-fluid" />
            <h5 className="h5 title">Prevenindo a lesão por pressão</h5>
            <h6 className="h6 title">Melhore a segurança do paciente</h6>
            <h6 className="h6 title">e reduza custos</h6>
            <div className="img-content w-100 h-100">&nbsp;</div>
          </div>
        </div>
        <div className="col-login col-right">
          <div
            className="content content-edit"
            style={{
              // maxHeight: "100%",
              // maxHeight: "59vh",
              overflow:
                signInUp == "in"
                  ? generalError != ""
                    ? "auto"
                    : "visible"
                  : "auto",
            }}
          >
            <Row className="tabs">
              <Col>
                <h6
                  className={signInUp == "in" ? "active-text" : ""}
                  onClick={() => changeSignInUp("in")}
                >
                  Acesse
                </h6>
              </Col>
              {/* <Col>
                <h6
                  className={signInUp == "up" ? "active-text" : ""}
                  onClick={() => changeSignInUp("up")}
                >
                  Cadastre-se
                </h6>
              </Col> */}
            </Row>
            <Row className="tab-bar">
              <Col className={signInUp == "in" ? "active-tab" : ""} />
              {/* <Col className={signInUp == "up" ? "active-tab" : ""} /> */}
            </Row>

            {metodoLogin == null && (
              <div>
                {signInUp == "in" && (
                  <h3>Escolha como deseja acessar sua conta</h3>
                )}

                {signInUp == "up" && (
                  <h3>Escolha como deseja criar sua conta</h3>
                )}

                <div
                  className="default-btn mail-btn"
                  onClick={() => setMetodoLogin("email")}
                >
                  <div style={{ width: 22, marginLeft: 5 }}>
                    <Icon color="#fff" size={22} icon="email" />
                  </div>
                  <div className="btn-icon-txt" style={{ marginLeft: -27 }}>
                    Entrar com e-mail
                  </div>
                </div>

                <div className="default-btn face-btn">
                  <div style={{ width: 22, marginLeft: 5 }}>
                    <Icon color="#fff" size={22} icon="facebook" />
                  </div>
                  <div className="btn-icon-txt" style={{ marginLeft: -27 }}>
                    Entrar com Facebook
                  </div>
                </div>

                <div className="google-btn">Entrar com Google</div>
              </div>
            )}

            {metodoLogin == "email" && signInUp == "in" && (
              <div
                tabIndex={0}
                className="login-content"
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    validLogin &&
                    loadingBtn !== "flex"
                  ) {
                    RunCaptcha();
                  }
                }}
              >
                <h3>Olá, bem-vindo de volta</h3>

                {generalError != "" && (
                  <div
                    className="container-error"
                    onClick={() => setGeneralError("")}
                  >
                    <p className="m-0">{generalError}</p>
                  </div>
                )}

                <form>
                  <Row className="margin-fields">
                    <Col>
                      <h6 className="field-label">E-mail</h6>
                      <input
                        type="text"
                        name="email"
                        placeholder="Digite seu e-mail"
                        onChange={(v) => setEmail(v.target.value)}
                        className={
                          submit && erroEmail.length > 0
                            ? "invalid-field default-input"
                            : "default-input"
                        }
                      />
                      {submit && erroEmail.length > 0 && (
                        <div className="invalid-msg">{erroEmail}</div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <h6 className="field-label">Senha</h6>
                      <div
                        className={
                          submit && senha.length <= 0
                            ? "invalid-field default-input last-input"
                            : "default-input last-input"
                        }
                      >
                        <input
                          type={viewPwd ? "text" : "password"}
                          name="Senha"
                          placeholder="Digite sua senha"
                          onChange={(v) => setSenha(v.target.value)}
                          className="borderless-input"
                        />
                        <div onClick={() => setViewPwd(!viewPwd)}>
                          <Icon
                            color={Colors.darker_gray}
                            size={windowSize[1] < 728 ? 20 : 24}
                            icon={viewPwd ? "eye-off" : "eye-on"}
                          />
                        </div>
                      </div>
                      {submit && senha.length <= 0 && (
                        <div className="invalid-msg">
                          Este campo é obrigatório
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="margin-link">
                    <Col style={{ textAlign: "right" }}>
                      <a href="/EsqueciSenha" className="pink-link">
                        Esqueci minha senha
                      </a>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !loadingBtn && validLogin) {
                          RunCaptcha();
                        }
                      }}
                    >
                      <div
                        className={
                          validLogin
                            ? "default-btn send-btn mb-1"
                            : "default-btn disabled send-btn mb-1"
                        }
                        onClick={(e) => {
                          if (loadingBtn === "flex") {
                            e.preventDefault();
                          } else {
                            RunCaptcha();
                          }
                        }}
                      >
                        <p
                          style={{
                            display: loadingBtn == "none" ? "flex" : "none",
                            margin: 0,
                          }}
                        >
                          Entrar
                        </p>

                        <Spinner
                          style={{ display: loadingBtn }}
                          animation="border"
                          role="status"
                          variant="light"
                          size="sm"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}

            {metodoLogin == "email" && signInUp == "up" && (
              <div className="login-content">
                <h3>Crie sua conta na Preveni</h3>
                <form>
                  <Container className="container-cadastro">
                    <Row className="margin-fields">
                      <Col>
                        <h6 className="field-label">Nome</h6>
                        <input
                          type="text"
                          name="nome"
                          placeholder="Digite seu nome"
                          onChange={(v) => setNome(v.target.value)}
                          className={
                            submit && nome.length <= 0
                              ? "invalid-field default-input"
                              : "default-input"
                          }
                        />
                        {submit && nome.length <= 0 && (
                          <div className="invalid-msg">
                            Este campo é obrigatório
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="margin-fields">
                      <Col>
                        <h6 className="field-label">E-mail</h6>
                        <input
                          type="text"
                          name="email"
                          placeholder="Digite seu e-mail"
                          onChange={(v) => setEmail(v.target.value)}
                          className={
                            submit &&
                            (email.length <= 0 || erroEmail.length > 0)
                              ? "invalid-field default-input"
                              : "default-input"
                          }
                        />
                        {submit &&
                          (email.length <= 0 || erroEmail.length > 0) && (
                            <div className="invalid-msg">{erroEmail}</div>
                          )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <h6 className="field-label">Senha</h6>
                        <div
                          className={
                            submit && senha.length <= 0
                              ? "invalid-field default-input last-input"
                              : "default-input last-input"
                          }
                        >
                          <input
                            type={viewPwd ? "text" : "password"}
                            name="Senha"
                            placeholder="Digite sua senha"
                            onChange={(v) => setSenha(v.target.value)}
                            className="borderless-input"
                          />
                          <div onClick={() => setViewPwd(!viewPwd)}>
                            <Icon
                              color={Colors.darker_gray}
                              size={24}
                              icon={viewPwd ? "eye-off" : "eye-on"}
                            />
                          </div>
                        </div>
                        {submit && senha.length <= 0 && (
                          <div className="invalid-msg">
                            Este campo é obrigatório
                          </div>
                        )}
                        <PwStrengthMeter value={senha} handler={setLevelPwd} />
                      </Col>
                    </Row>

                    <Row
                      className="mx-0"
                      style={{ marginTop: 20, alignItems: "center" }}
                    >
                      <Col xs={2} md={2} lg={2} className="pl-0 pr-1">
                        <CheckBox checked={aceito} onclick={checkAceito} />
                      </Col>
                      <Col xs={10} md={10} lg={10} className="pr-0">
                        <Row>
                          <div className="check-text">
                            Li e concordo com os&nbsp;
                          </div>
                          <div
                            className="pink-link m-0"
                            onClick={() => setShowModalTermos(true)}
                          >
                            Termos de uso&nbsp;
                          </div>
                          <div className="check-text">e&nbsp;</div>
                          <div
                            className="pink-link m-0"
                            onClick={() => setShowModalPolitica(true)}
                          >
                            Política de privacidade&nbsp;
                          </div>
                          <div className="check-text">da Preveni&nbsp;</div>
                        </Row>
                      </Col>
                    </Row>
                  </Container>

                  <Row>
                    <Col>
                      <div
                        className={
                          validCadastro
                            ? "default-btn send-btn mb-1"
                            : "default-btn disabled send-btn mb-1"
                        }
                        onClick={() => Cadastro()}
                      >
                        <p
                          style={{
                            display:
                              loadingBtnRegister == "none" ? "flex" : "none",
                            margin: 0,
                          }}
                        >
                          Criar conta
                        </p>

                        <Spinner
                          style={{ display: loadingBtnRegister }}
                          animation="border"
                          role="status"
                          variant="light"
                          size="sm"
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Login;
