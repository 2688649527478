import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Dropdown,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Sound from "react-sound";
import Ws from "@adonisjs/websocket-client";
import moment from "moment";

import MainNavbar from "../../../components/Nav/MainNavbar";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import { ToggleFullScreen, ConvertRuntime } from "../../../functions/utils";
import ModalMdd from "../../../components/Modals/ModalMdd";
import ModalSensor from "../../../components/Modals/ModalSensor";
import DecubitMapDropdown from "../../../components/Dropdowns/DecubitMapDropdown";
import ZoomDecubitMapDropdown from "../../../components/Dropdowns/ZoomDecubitMapDropdown";
import soundFile from "../../../assets/sound/alert_sound.mp3";
import ModalSensorLowSignal from "../../../components/Modals/ModalSensorLowSignal";
import orderSensor from "../OrderSensor";
import "./style.css";
import ModalLowBatterySensors from "../../../components/Modals/ModalLowBatterySensors";

var last_date = null;
var listNotifications2 = [];
var timeSound = 1;
const typeAdd = "minutes";
var room = "";
var unitCopy = 0;
var isLoadingStatusSort = false;
var isLoadingRoomSort = false;
var beaconLowSignal = null;
var loadingFirstMdd = false;
var isLoadingRequest = false;

const priorities = {
  high: "#FF7B49",
  medium: "#FFC82C",
  low: "#DBDBDB",
};

function MudancaDecubitoNew({ history }) {
  function playSound() {
    new Audio(soundFile).play();
  }

  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isProgressive, setIsProgressive] = useState(false);
  const [showModalMdd, setShowModalMdd] = useState(false);
  const [showModalLowSignal, setShowModalLowSignal] = useState(false);
  const [suspendModalLowSignalAlert, setSuspendModalLowSignalAlert] =
    useState(false);
  const [showModalSensor, setShowModalSensor] = useState(false);
  const [sort, setSort] = useState({
    icon: "bed",
    value: "room",
    name: "Quarto / leito",
  });
  const [zoom, setZoom] = useState({
    icon: "zoom-in",
    value: 3,
    name: "100%",
  });
  const [sizeIcon, setSizeicon] = useState(16);
  const [startListNotification, setStartListNotification] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [colMobile, setColmobile] = useState(6);

  const [dados, setDados] = useState({});
  const [listNotifications, setListNotifications] = useState([]);
  //const [play, setPlay] = useState(false);
  const [verifySound, setVerifySound] = useState(false);
  const [updateTime, setUpdateTime] = useState(false);
  const [chooser, setChooser] = useState({});
  const [lowSignalBeacon, setLowSignalBeacon] = useState(null);
  const [showNavbar, setShowNavbar] = useState(true);
  const [wsStatus, setWsStatus] = useState(true);
  const [firstItemLoaded, setFirstItemLoaded] = useState(false);
  const [firstLoadedComplete, setFirstLoadedComplete] = useState(false);
  const [failedMddLoad, setFailedMddLoad] = useState(false);
  const [mddFailedCount, setMddFailedCount] = useState(0);
  const [waitMddLoad, setWaitMddLoad] = useState(false);
  const [showLowBatterySensorsModal, setShowLowBatterySensorsModal] =
    useState(false);
  const [lowBatterySensors, setLowBatterySensors] = useState([]);

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       //sound: true,
  //       audio: true,
  //       //video: true,
  //     })
  //     .then(function(stream) {
  //       console.log("stream");
  //       console.log(stream);
  //     })
  //     .catch(function(err) {
  //       console.log("err");
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    function WebSocketInit() {
      //Web Socket Adonis
      const ws = Ws(`${process.env.REACT_APP_WEB_SOCKET_ENDPOINT}`, {
        path: "ws",
        reconnection: false,
      });
      ws.connect();
      const topic = ws.subscribe("websocket");
      topic.on("ready", () => {
        topic.on("notification", (response) => {
          console.log("[ws ml] New decubito");
          var obj = response.data;
          console.log(obj);
          console.log("==========================");
          console.log("UNIT COPY " + unitCopy);
          console.log("==========================");
          if (
            obj.unit_id != unitCopy ||
            obj?.unit_id == undefined ||
            obj?.patient_id == undefined
          ) {
            return false;
          }

          /*if (obj && obj.beacon) {
            if (
              obj.beacon.beacon_in_room !== undefined &&
              !obj.beacon.beacon_in_room &&
              obj.unit_id == unit.selecionado.id
            ) {
              if (!beaconLowSignal) {
                beaconLowSignal = obj;
              }
              setShowModalLowSignal(true);
            } else {
              if (beaconLowSignal && beaconLowSignal.beacon) {
                if (obj.beacon.id === beaconLowSignal.beacon.id) {
                  beaconLowSignal = null;
                }
              }
            }
          }*/

          //Reset card
          if (obj.tag == "forget") {
            // var newList = listNotifications2.filter(
            //   (item) => item.notification_id != obj.notification_id
            // );
            // console.log("newList filter");
            // console.log(newList);

            var newList = listNotifications2.map((item) => {
              if (item.room_name == obj.room) {
                if (item.subrooms == undefined) {
                  item.notification = undefined;
                  item.patient_name = obj.name;
                  item.age = obj.age;
                  item.date_birth = obj.date_birth;
                  item.patient_initials = obj.initials;
                  item.room_name = obj.room;
                  item.patient_id = obj.patient_id;
                } else {
                  item.subrooms.map((i) => {
                    if (i.room_name == obj.subroom) {
                      i.notification = undefined;
                      i.patient_name = obj.name;
                      i.age = obj.age;
                      i.date_birth = obj.date_birth;
                      i.patient_initials = obj.initials;
                      i.room_name = obj.subroom;
                      i.patient_id = obj.patient_id;
                    }
                  });
                }
              }
              return item;
            });
            //setListNotifications(newList);
            listNotifications2 = newList;
            return false;
          }

          if (obj?.beacon?.id == undefined && !obj.link_beacon) {
            return false;
          }

          var newList = listNotifications2.map((item) => {
            if (item.room_name == obj.room) {
              if (item.subrooms == undefined) {
                item.notification = obj;
                item.patient_name = obj.name;
                item.age = obj.age;
                item.date_birth = obj.date_birth;
                item.patient_initials = obj.initials;
                item.room_name = obj.room;
              } else {
                item.subrooms.map((i) => {
                  if (i.room_name == obj.subroom) {
                    i.notification = obj;
                    i.patient_name = obj.name;
                    i.age = obj.age;
                    i.date_birth = obj.date_birth;
                    i.patient_initials = obj.initials;
                    i.room_name = obj.subroom;
                  }
                });
              }
            }
            return item;
          });

          //setListNotifications(newList);
          listNotifications2 = newList;
          /*if (obj.sound) {
            playSound();
            last_date = moment().add(timeSound, typeAdd);
          }*/
        });
      });

      ws.on("open", () => {
        console.log("ws connect ml");
        setWsStatus(true);
      });

      ws.on("close", () => {
        setWsStatus(false);
        console.log("ws disconnect ml");
        setTimeout(() => {
          WebSocketInit();
        }, 9000);
      });
    }

    if (
      unit.selecionado?.id != undefined &&
      unit.selecionado?.url_friendly != "all-units"
    ) {
      WebSocketInit();
    }
  }, [unit]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) unitCopy = unit.selecionado.id;
  }, [unit]);

  useLayoutEffect(() => {
    function update() {
      setExpanded(false);
      setWindowSize([window.innerWidth, window.innerHeight]);
      setMobile(windowSize[0] < 768);
    }
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);

  useEffect(() => {
    setMobile(windowSize[0] < 768);
  }, windowSize);

  useEffect(() => {
    function LoadTimeSound() {
      api
        .get(`/hospitals/${user.dados.hospital_id}`)
        .then((response) => {
          if (response.status == 200) {
            timeSound = response.data.time_sound;
          }
        })
        .catch((error) => {
          console.log("LoadTimeSound error " + error);
        });
    }

    function LoadCheckIfHospitalIsProgressiveMdd() {
      api
        .get("/default-settings/hospitals/progressive-time")
        .then((response) => {
          if (response.status == 200) {
            setIsProgressive(response.data.is_progressive);
          }
        })
        .catch((error) => {
          console.log("LoadCheckIfHospitalIsProgressiveMdd error " + error);
        });
    }

    LoadTimeSound();
    LoadCheckIfHospitalIsProgressiveMdd();
  }, []);

  useEffect(() => {
    /*
      Compara se a ultima data salva com os minutos 
      do hospital já adicionados, é menor qua a data atual
    */
    // if (last_date != null) {
    //   if (last_date.isBefore(moment())) {
    //     VerifySound();
    //   }
    // }

    VerifySound();
    setTimeout(() => {
      setVerifySound(!verifySound);
    }, 1000);
  }, [verifySound]);

  function VerifySound() {
    //last_date = null;
    // if (last_date != null) {
    //   console.log("last_date.isBefore " + last_date.isBefore(moment()));
    // }
    listNotifications2.map((item) => {
      if (
        item.notification != undefined &&
        (item.subrooms == undefined || item.subrooms.length == 0)
      ) {
        if (item.notification.sound) {
          if (last_date == null || last_date.isBefore(moment())) {
            console.log("timeSound " + timeSound);
            last_date = moment().add(timeSound, typeAdd);
            //setPlay(true);
            playSound();
          }
        }
      } else if (item.subrooms) {
        item.subrooms.map((i) => {
          if (i.notification != undefined) {
            if (i.notification.sound) {
              if (last_date == null || last_date.isBefore(moment())) {
                console.log("timeSound " + timeSound);
                last_date = moment().add(timeSound, typeAdd);
                //setPlay(true);
                playSound();
              }
            }
          }
        });
      }
    });
  }

  useEffect(() => {
    if (
      unit.selecionado?.id != undefined &&
      unit.selecionado?.url_friendly != "all-units"
    ) {
      LoadNotifications();
    } else {
      setLoading(false);
    }
  }, [unit, reload]);

  useEffect(() => {
    let intervalId;
    if (firstLoadedComplete) {
      intervalId = setInterval(() => {
        setReload(!reload);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [firstLoadedComplete, waitMddLoad, reload]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      setLoading(true);
    }
  }, [unit]);

  useEffect(() => {
    setLoading(true);
    if (sort.value === "status") {
      isLoadingStatusSort = true;
    }
    if (loadingFirstMdd) {
      LoadNotifications();
    }
  }, [sort]);

  useEffect(() => {
    if (zoom.value == 3) setSizeicon(20);
    if (zoom.value == 4) setSizeicon(20);
    if (zoom.value == 6) setSizeicon(24);
    if (mobile) {
      if (zoom.value == 3) setColmobile(6);
      if (zoom.value == 4) setColmobile(6);
      if (zoom.value == 6) setColmobile(12);
    }
    LoadNotifications();
  }, [zoom]);

  useEffect(() => {
    if (failedMddLoad) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Não foi possível carregar a lista de mapa de leitos",
        })
      );
    }
  }, [failedMddLoad]);

  function LoadNotifications() {
    if (sort.value === "room" && !isLoadingRoomSort) {
      setLoading(true);
    }
    if (sort.value === "status" && isLoadingStatusSort) {
      setLoading(true);
    }

    if (isLoadingRequest || waitMddLoad) {
      return;
    }

    api
      .get(`/notifications/beacons/mdd`, {
        params: {
          unit_id: unit.selecionado.id,
        },
      })
      .then((response) => {
        setFailedMddLoad(false);
        isLoadingRequest = true;
        if (response.status == 200) {
          if (sort.value === "room") {
            setListNotifications(response.data);
            isLoadingRoomSort = true;
            isLoadingRequest = false;
          } else if (sort.value === "status") {
            isLoadingRoomSort = false;
            let notifications = response.data;
            let formatedNotifications = orderSensor(notifications);
            setListNotifications(formatedNotifications);
            isLoadingStatusSort = false;
            isLoadingRequest = false;
          }
        }
      })
      .catch((error) => {
        console.log("LoadNotifications error " + error);
        console.log(mddFailedCount);
        if (!failedMddLoad && mddFailedCount <= 2) {
          setMddFailedCount(mddFailedCount + 1);
          setListNotifications([]);
          setFailedMddLoad(true);
        }
      })
      .finally(() => {
        setFirstLoadedComplete(true);
        if (isLoadingStatusSort) {
          setLoading(true);
        } else {
          setLoading(false);
        }
      });
  }

  function Calculate(datetime) {
    var diffS = "";
    var isBefore = false;
    if (moment().isBefore(moment(datetime))) {
      isBefore = true;
      diffS = moment(datetime).diff(moment(), "minutes");
    } else {
      diffS = moment().diff(moment(datetime), "minutes");
    }
    var newDiff = `${!isBefore ? "-" : ""}${ConvertRuntime(diffS)}`;
    //var newDiff = `${ConvertRuntime(diffS)}`;

    // console.log("======================");
    // console.log("DATETIME " + datetime);
    // console.log("diffS " + diffS);
    // console.log("newDiff " + newDiff);
    // console.log("======================");

    return { diffS, newDiff, isBefore };
  }

  useEffect(() => {
    let lowBatterySensors = [];
    var newList = listNotifications.map((item, index) => {
      let patientWithSensorData = {};
      if (isLoadingRoomSort) {
        if (item.subrooms.length == 0) {
          if (
            item?.notification != undefined &&
            item?.notification?.beacon?.id != undefined
          ) {
            patientWithSensorData = item;
            if (item.time_suspend == "- 1 min") {
              setReload(!reload);
            }
          }
        } else {
          item.subrooms.map((i) => {
            if (
              i?.notification != undefined &&
              i?.notification?.beacon?.id != undefined
            ) {
              patientWithSensorData = i;
              if (i.time_suspend == "- 1 min") {
                setReload(!reload);
              }
            }
          });
        }
      } else if (sort.value === "status") {
        if (
          item?.notification != undefined &&
          item?.notification?.beacon?.id != undefined
        ) {
          if (item.time_suspend == "- 1 min") {
            setReload(!reload);
          }
        }
        if (item?.notification?.beacon?.battery) {
          patientWithSensorData = item;
        }
      }
      if (
        patientWithSensorData?.notification?.beacon &&
        parseInt(patientWithSensorData?.notification?.beacon?.battery) < 30
      ) {
        lowBatterySensors.push(patientWithSensorData);
      }
      setLowBatterySensors(lowBatterySensors);
      return item;
    });

    if (sort.value === "status" && loadingFirstMdd) {
      let formatedNotifications = orderSensor(listNotifications);
      isLoadingRoomSort = false;
      setListNotifications(formatedNotifications);
      isLoadingStatusSort = false;
    } else {
      setListNotifications(newList);
      loadingFirstMdd = true;
    }
    listNotifications2 = newList;
    setTimeout(() => {
      setUpdateTime(!updateTime);
    }, 1000); //60000
  }, [updateTime]); // listNotifications estava como dependencia

  // console.log("chooser ");
  // console.log(chooser);

  function VerifyPosition(notification) {
    var ret = true;
    if (notification.tag == "realized") {
      ret = false;

      if (notification.color == "#FF7B49") {
        return true;
      }
    }
    return ret;
  }

  function showNextPositionInCard(item) {
    if (item.suspended?.is_paused) {
      return false;
    }
    if (item.notification && isProgressive) {
      if (
        item.notification.color == "#FFC82C" ||
        item.notification.color == "#FF7B49"
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  function showArrow(item) {
    return (
      showNextPositionInCard(item) &&
      item.notification.beacon.position_abb_actual !=
        item.notification.beacon.position_name_abb &&
      VerifyPosition(item.notification) &&
      item.notification.beacon.position_name_abb != ""
    );
  }

  function lowBatterySensorFooterMessage(lowBatterySensors) {
    if (lowBatterySensors.length > 1) {
      return `Os sensores de ${lowBatterySensors.length} pacientes estão com a bateria baixa`;
    } else {
      return `O sensor do paciente ${lowBatterySensors[0].patient_name} está com a bateria baixa`;
    }
  }

  function resizeIconSubtitle() {
    let width = window.innerWidth;
    if (width >= 425) {
      return 23;
    }
    return 28;
  }

  function RenderCard(item, isSubroom, room_name, index) {
    if (item.patient_name != null && item?.notification != undefined) {
      let cardWarning = "";
      if (
        !item.notification.link_beacon &&
        item.notification.beacon &&
        item.notification?.beacon.signal_beacon &&
        item.notification?.tag != "suspend" &&
        !item.notification.suspendFlag
      ) {
        if (
          !item.notification.beacon.position_corrected ||
          item.notification.color === "FF7B49"
        ) {
          cardWarning = "mdd-card-warning-color";
        }
        if (item.notification.color === "#FFC82C") {
          cardWarning = "mdd-card-soon-color";
        }
      }
      return (
        <Col
          key={index}
          xs={12}
          sm={colMobile}
          md={6}
          xl={zoom.value}
          className="my-2"
        >
          <div
            className={`mdd-card-sensor ${cardWarning} mdd-card-sensor-${zoom.value}`}
            onClick={() => {
              room = "";
              if (isSubroom) {
                room = room_name;
              } else if (sort.value == "status" && item?.highRoomName) {
                room = item.highRoomName;
              }
              setChooser(item);
              if (
                item.notification?.beacon?.id == undefined ||
                item.notification.unlink_beacon
              ) {
                setShowModalSensor(true);
              } else {
                LoadNotifications();
                setShowModalMdd(true);
              }
            }}
          >
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
              }}
            >
              <div className="d-flex justify-content-between mb-2">
                <p className={`card-name card-name-mdd-${zoom.value}`}>
                  {item.patient_initials}
                </p>
                {item.notification?.beacon?.id != undefined &&
                  !item?.notification?.unlink_beacon && (
                    <div className="d-flex">
                      {!item.notification?.suspendFlag ? (
                        <div
                          className={`mdd-ball-${zoom.value}`}
                          style={{
                            // backgroundColor: GetTagColor(
                            //   item.notification?.tag
                            // ),
                            backgroundColor: item?.notification.color,
                          }}
                        />
                      ) : (
                        <Icon
                          className={`mdd-bell-off-${zoom.value}`}
                          icon={
                            item.suspended.is_paused ? "no-time" : "bell-off"
                          }
                          size={24}
                          color={
                            item.suspended.is_paused ? "" : Colors.dark_gray
                          }
                        />
                      )}
                    </div>
                  )}
              </div>

              <div className="d-flex justify-content-between">
                {sort.value === "status" && (
                  <div
                    className={`card-quarto card-quarto-mdd mb-3 mdd-text-zoom-${zoom.value}`}
                  >
                    {item.highRoomName ? item.highRoomName : room_name}{" "}
                    {item.highRoomName && `| ${item.room_name}`}
                  </div>
                )}
                {sort.value === "room" && (
                  <div
                    className={`card-quarto card-quarto-mdd mb-3 mdd-text-zoom-${zoom.value}`}
                  >
                    {room_name} {isSubroom && <span>|</span>}{" "}
                    {isSubroom && item.room_name}
                  </div>
                )}
                {!item?.notification?.unlink_beacon &&
                  item?.notification.beacon.signal_beacon && (
                    <p className={`mdd-time mdd-text-zoom-${zoom.value}`}>
                      {!item.notification.suspendFlag
                        ? item.different
                        : item.suspended?.minutes === "INDETERMINATE"
                        ? "Indeterm."
                        : item.time_suspend}
                    </p>
                  )}
              </div>
            </div>

            {item.notification?.beacon?.id != undefined &&
            !item.notification?.link_beacon ? (
              <>
                {!item.notification?.unlink_beacon ? (
                  <div
                    className="d-flex justify-content-between mt-3"
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingBottom: 16,
                    }}
                  >
                    {item.notification.beacon.signal_beacon ? (
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <Icon
                            className="mdd-icon"
                            icon={
                              item.notification.beacon.position_corrected ||
                              item?.suspended?.is_paused
                                ? "beds"
                                : "warning"
                            }
                            size={sizeIcon}
                            color={
                              item.notification.beacon.position_color ==
                                "#666666" || item?.suspended?.is_paused
                                ? Colors.brand_blue
                                : item.notification.beacon.position_color
                            }
                          />
                          <p
                            className={`mdd-footer-text mdd-text-zoom-${
                              zoom.value
                            } ${
                              !(
                                item.notification.beacon.position_corrected &&
                                item.notification.tag !== "soon"
                              ) &&
                              item.notification.beacon.position_actual.includes(
                                "/"
                              ) &&
                              zoom.value === 4
                                ? "d-flex flex-column"
                                : ""
                            }`}
                            style={{
                              color: item?.suspended?.is_paused
                                ? "#666666"
                                : item.notification.beacon.position_color,
                            }}
                          >
                            {(item.notification.beacon.position_corrected &&
                              item.notification.tag !== "soon" &&
                              !showArrow(item)) ||
                            item?.suspended?.is_paused ? (
                              item.notification.beacon.position_actual
                            ) : zoom.value !== 3 ? (
                              <>
                                {zoom.value == 6 ? (
                                  <>
                                    {item.notification.beacon.position_actual}
                                  </>
                                ) : (
                                  <>
                                    {item.notification.beacon.position_actual.split(
                                      "/"
                                    )[0] +
                                      (item.notification.beacon.position_actual?.includes(
                                        "/"
                                      )
                                        ? "/"
                                        : "")}
                                  </>
                                )}
                                {!(
                                  item.notification.beacon.position_corrected &&
                                  item.notification.tag !== "soon"
                                ) &&
                                  item.notification.beacon.position_actual.includes(
                                    "/"
                                  ) &&
                                  zoom.value === 4 && (
                                    <span>
                                      {
                                        item.notification.beacon.position_actual.split(
                                          "/"
                                        )[1]
                                      }
                                    </span>
                                  )}
                              </>
                            ) : (
                              item.notification.beacon.position_abb_actual
                            )}
                          </p>
                        </div>

                        {showArrow(item) && (
                          <>
                            <div className="mdd-arrow">
                              <Icon
                                icon={"arrow-right-1"}
                                size={20}
                                color={Colors.dark_gray}
                              />
                            </div>

                            <div className="d-flex align-items-center">
                              <Icon
                                icon={"refresh"}
                                size={sizeIcon}
                                color={Colors.brand_blue}
                              />
                              <p
                                className={`mdd-footer-text mdd-text-zoom-${zoom.value}`}
                              >
                                {zoom.value != 3
                                  ? item.notification.beacon.position_name
                                  : item.notification.beacon.position_name_abb}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Icon
                          icon={"warning"}
                          size={sizeIcon}
                          color={Colors.new_orange}
                        />
                        <p
                          className={`mdd-footer-text mdd-text-zoom-${zoom.value}`}
                          style={{
                            color: Colors.new_orange,
                          }}
                        >
                          SEM SINAL
                        </p>
                      </div>
                    )}

                    {item.notification.beacon.signal_beacon && (
                      <div className="d-flex align-items-center">
                        <Icon
                          className="mdd-icon"
                          icon={
                            item.notification.beacon.angle_corrected
                              ? "angle"
                              : "warning"
                          }
                          size={sizeIcon}
                          color={
                            item.notification.beacon.angle_corrected
                              ? Colors.brand_blue
                              : Colors.new_orange
                          }
                        />
                        <p
                          className={`mdd-footer-text mdd-text-zoom-${zoom.value}`}
                          style={{
                            color: item.notification.beacon.angle_corrected
                              ? Colors.darkest_gray
                              : Colors.new_orange,
                          }}
                        >
                          {item.notification.beacon.main_angle}°
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <button className="btn-desvincular">
                    <p className={`mdd-text-vinc-zoom-${zoom.value}`}>
                      Desvincular sensor
                    </p>
                  </button>
                )}
              </>
            ) : (
              <>
                <button className="btn-vincular">
                  <p className={`mdd-text-vinc-zoom-${zoom.value}`}>
                    Vincular sensor
                  </p>
                </button>
              </>
            )}
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          key={index}
          xs={12}
          sm={colMobile}
          md={6}
          xl={zoom.value}
          className="my-2"
        >
          <div
            className={`main-card main-card-empty mdd-card-sensor-${zoom.value}`}
          >
            {sort.value === "status" && (
              <div>
                <p className={`mdd-text-zoom-${zoom.value}`}>
                  {item.highRoomName ? item.highRoomName : room_name}{" "}
                  {item.highRoomName && <span>|</span>}
                  {item.highRoomName && item.room_name}
                </p>
              </div>
            )}
            {sort.value === "room" && (
              <div>
                <p className={`mdd-text-zoom-${zoom.value}`}>
                  {room_name} {isSubroom && <span>|</span>}
                  {isSubroom && item.room_name}
                </p>
              </div>
            )}
          </div>
        </Col>
      );
    }
  }

  return (
    <div id="container-all-here">
      {showNavbar && <MainNavbar history={history} screen={"decubit-map"} />}

      {/* <Sound
        url={soundFile}
        playStatus={play ? "PLAYING" : "STOPPED"}
        onFinishedPlaying={() => setPlay(false)}
        volume={100}
      /> */}

      {showModalMdd && (
        <ModalMdd
          data={chooser}
          handlerWaitMddLoad={setWaitMddLoad}
          beacon={chooser?.notification?.beacon}
          handler_modal={setShowModalMdd}
          show_modal={showModalMdd}
          handler_reload={setReload}
          reload={reload}
          handler_loading={setLoading}
          position_actual={chooser?.notification?.beacon?.position_actual}
          position_to={chooser?.notification?.beacon?.position_name}
          notification_id={chooser?.notification.notification_id}
          patient_name={chooser.patient_name}
          patient_id={chooser.patient_id}
          room={room}
          subroom={chooser.room_name}
          tag={chooser?.notification.tag}
          different={chooser?.different}
          beacon_time={chooser?.notification?.beacon?.time}
          tag_color={chooser?.notification.color}
          suspended={chooser.suspended}
          suspendedFlag={chooser?.notification.suspendFlag}
          isProgressive={chooser?.notification.isProgressive}
          timeProgressive={chooser?.notification.timeProgressive}
          defaultTimeProgressive={chooser?.notification.defaultTimeProgressive}
          history={history}
        />
      )}

      {showModalLowSignal && !suspendModalLowSignalAlert && (
        <ModalSensorLowSignal
          handler_modal={setShowModalLowSignal}
          show_modal={showModalLowSignal}
          beacon_name={beaconLowSignal.beacon.name}
          beacon_id={beaconLowSignal.beacon.id}
          unit_id={beaconLowSignal.unit_id}
          handler_suspend_alert={setSuspendModalLowSignalAlert}
        />
      )}

      {showModalSensor && (
        <ModalSensor
          data={chooser}
          handler_modal={setShowModalSensor}
          show_modal={showModalSensor}
          link={chooser.notification?.link_beacon}
          handler_reload={setReload}
          reload={reload}
          patient_id={chooser.patient_id}
          room={room}
          subroom={chooser.room_name}
          date_birth={chooser.date_birth}
          age={chooser.age}
          patient_name={chooser.patient_name}
          beacon_name={chooser?.notification?.beacon?.name}
          beacon_id={chooser?.notification?.beacon?.id}
          unit_id={chooser?.notification?.unit_id}
        />
      )}

      <Container fluid className="bg-white">
        <div className="pt-5 container-header-screen d-flex justify-content-between align-items-center">
          {mobile && (
            <button
              onClick={() => {
                history.goBack();
              }}
              style={{
                border: "none",
                background: "#FFF",
                width: 50,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon color={Colors.darker_gray} size={24} icon="arrow-left" />
            </button>
          )}

          <h2 className={`p-0 ${mobile ? "mdd-text-title-mobile" : ""}`}>
            Mapa de decúbito
          </h2>

          {mobile && (
            <button
              onClick={() => {
                setShowNavbar(!showNavbar);
                ToggleFullScreen();
              }}
              style={{
                border: "none",
                background: "#F5F6F9",
                width: 50,
                height: 50,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Icon color={Colors.darker_gray} size={24} icon="maximize" />
            </button>
          )}

          {!mobile && (
            <div className="d-flex align-items-center">
              <ZoomDecubitMapDropdown handler={setZoom} zoom={zoom} />
              <DecubitMapDropdown handler={setSort} sort={sort} />
              <button
                onClick={() => {
                  setShowNavbar(!showNavbar);
                  ToggleFullScreen();
                }}
                style={{
                  border: "none",
                  background: "#F5F6F9",
                  width: 50,
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                }}
              >
                <Icon color={Colors.darker_gray} size={24} icon="maximize" />
              </button>
            </div>
          )}
        </div>

        {!mobile && (
          <>
            <div className="mdd-label d-flex mb-3 mt-1 flex-column flex-md-row">
              <div className="mdd-label-item d-flex align-items-center">
                <div
                  className="mdd-ball-small"
                  style={{ backgroundColor: "#DBDBDB" }}
                />
                <p>Realizada</p>
              </div>

              <div className="mdd-label-item d-flex align-items-center">
                <div
                  className="mdd-ball-small"
                  style={{ backgroundColor: Colors.brand_yellow }}
                />
                <p>Próxima</p>
              </div>

              <div className="mdd-label-item d-flex align-items-center">
                <div
                  className="mdd-ball-small"
                  style={{ backgroundColor: "#FF7B49" }}
                />
                <p>Atrasada {!isProgressive ? "/ Incorreta" : ""}</p>
              </div>

              <div className="mdd-label-item d-flex align-items-center">
                <Icon icon={"bell-off"} size={16} color={Colors.dark_gray} />
                <p>Aviso suspenso</p>
              </div>
              <div className="mdd-label-item d-flex align-items-center">
                <Icon icon={"no-time"} size={16} />
                <p>Tempo Pausado</p>
              </div>

              {/* <div className="mdd-label-item d-flex align-items-center">
                <Icon icon={"warning"} size={16} color={Colors.dark_gray} />
                <p>Não realizada</p>
              </div> */}
            </div>
          </>
        )}

        {mobile && (
          <>
            <Row
              className="justify-content-start align-items-center"
              style={{ marginTop: 25, marginBottom: 25 }}
            >
              <Col sm={12}>
                <Row className="d-flex">
                  <Col md={4} className="d-flex">
                    <div className="mdd-label-item d-flex align-items-center">
                      <div
                        className="mdd-ball-small mr-2"
                        style={{ backgroundColor: "#DBDBDB" }}
                      />
                      <p>Realizada</p>
                    </div>

                    <div className="mdd-label-item d-flex align-items-center">
                      <div
                        className="mdd-ball-small mr-2"
                        style={{ backgroundColor: Colors.brand_yellow }}
                      />
                      <p>Próxima</p>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex">
                  <Col md={4} className="d-flex">
                    <div className="mdd-label-item d-flex align-items-center">
                      <div
                        className="mdd-ball-small mr-2"
                        style={{ backgroundColor: "#FF7B49" }}
                      />
                      <p>Atrasada {!isProgressive ? "/ Incorreta" : ""}</p>
                    </div>

                    <div
                      className="mdd-label-item d-flex align-items-center"
                      style={{
                        marginLeft: "5px",
                        marginRight: "0px",
                      }}
                    >
                      <Icon
                        icon={"bell-off"}
                        size={resizeIconSubtitle()}
                        color={Colors.dark_gray}
                        className={"mr-2"}
                      />
                      <p>Aviso suspenso</p>
                    </div>

                    <div className="mdd-label-item d-flex align-items-center">
                      <Icon
                        icon={"no-time"}
                        size={resizeIconSubtitle()}
                        className={"mr-2"}
                      />
                      <p>Tempo Pausado</p>
                    </div>

                    {/* <div className="mdd-label-item d-flex align-items-center">
                      <Icon icon={"warning"} size={16} color={Colors.dark_gray} />
                      <p>Não realizada</p>
                    </div> */}
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row
                  className="d-flex zoom-mobile-sm"
                  style={{ marginLeft: 43 }}
                >
                  <ZoomDecubitMapDropdown handler={setZoom} zoom={zoom} />
                  <DecubitMapDropdown handler={setSort} sort={sort} />
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Row className="justify-content-start align-items-center">
          {loading && (
            <div className="mt-3 w-100 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!loading && listNotifications.length == 0 && (
            <div className="container-error-default">
              <p>Nenhum resultado foi encontrado.</p>
            </div>
          )}

          {!loading &&
            listNotifications.map((item, index) => {
              if (isLoadingRoomSort) {
                if (item.subrooms == undefined || item.subrooms.length == 0) {
                  return RenderCard(item, false, item.room_name, index);
                } else {
                  return item.subrooms.map((i, a) => {
                    return RenderCard(i, true, item.room_name, index);
                  });
                }
              } else if (sort.value === "status") {
                return RenderCard(item, false, item.room_name, index);
              } else {
                if (firstItemLoaded) {
                  setLoading(true);
                } else {
                  setFirstItemLoaded(true);
                }
              }
            })}
        </Row>
      </Container>
      {false && lowBatterySensors.length > 0 && (
        <div className="alert-low-battery-bottom">
          <div className="d-flex justify-content-center align-items-center alert-low-battery-bottom-content">
            <div className="alert-low-battery-bottom-description">
              <div className="circle-style">
                <Icon color={"#fff"} size={24} icon="warning" />
              </div>
              <p>{lowBatterySensorFooterMessage(lowBatterySensors)}</p>
            </div>
            <div
              className="alert-low-battery-bottom-description-details"
              onClick={() => {
                setShowLowBatterySensorsModal(true);
              }}
            >
              <p>Ver mais</p>
              <Icon icon="arrow-left" size={20} color={"#ffff"} />
            </div>
          </div>
        </div>
      )}
      <ModalLowBatterySensors
        show={showLowBatterySensorsModal}
        handler={setShowLowBatterySensorsModal}
        sensorsData={lowBatterySensors}
      />
    </div>
  );
}

export default MudancaDecubitoNew;
