const INITIAL_STATE = {
  selecionado: {
    id: 1,
    name: "Hospital Padrão",
    status: 1,
  },
};

export default function hospital(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_HOSPITAL":
      return {
        ...state,
        selecionado: action.selected,
      };
    default:
      return state;
  }
}
