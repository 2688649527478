import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { GetColorAvaliation, GetOrderIndexColor } from "../../functions/utils";
import EmptyContainer from "./EmptyContainer";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AnalysisCombinationsPie from "./AnalysisCombinationsPie";
import AnalysisComorbiditiesPie from "./AnalysisComorbiditiesPie";
import DOMPurify from "dompurify";

function Analysis() {
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(false);
  const [dateInterval, setDateInterval] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const [
    mostCommonComorbiditiesPercentages,
    setMostCommonComorbiditiesPercentages,
  ] = useState(null);
  const [
    mostCommonSituationalStatesPercentages,
    setMostCommonSituationalStatesPercentages,
  ] = useState(null);
  const [
    mostComorbiditiesCombinationsPercentage,
    setMostComorbiditiesCombinationsPercentage,
  ] = useState(null);

  function handleCallback(start, end) {
    setDateInterval({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  }

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadAnalysis();
    }
  }, [unit, dateInterval]);

  function LoadAnalysis() {
    setLoading(true);
    api
      .get(`/dashboard/analysis`, {
        params: {
          unitId: unit.selecionado.id,
          dateInitial: dateInterval.start,
          dateFinal: dateInterval.end,
        },
      })
      .then((response) => {
        if (response.status == 200 && response.data) {
          const analysisData = response.data;
          if (
            analysisData.mostCommonComorbiditiesPercentages &&
            analysisData.mostCommonComorbiditiesPercentages.length > 0
          ) {
            setMostCommonComorbiditiesPercentages(
              analysisData.mostCommonComorbiditiesPercentages
            );
          }
          if (
            analysisData.mostCommonSituationalStatesPercentages &&
            analysisData.mostCommonSituationalStatesPercentages.length > 0
          ) {
            setMostCommonSituationalStatesPercentages(
              analysisData.mostCommonSituationalStatesPercentages
            );
          }
          setMostComorbiditiesCombinationsPercentage(
            analysisData.mostComorbiditiesCombinationsPercentage
          );
        } else {
          setMostCommonComorbiditiesPercentages(null);
          setMostCommonSituationalStatesPercentages(null);
          setMostComorbiditiesCombinationsPercentage(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div>
        <Form.Label className="field-label">Período</Form.Label>
        <Row
          className="justify-content-start align-items-center mb-3 mx-0"
          style={{
            maxWidth: "248px",
          }}
        >
          <DateRangePicker
            initialSettings={{
              locale: {
                cancelLabel: "Cancelar",
                applyLabel: "Aplicar",
              },
              applyButtonClasses: "btn-dr-custom",
            }}
            onCallback={(start, end) => handleCallback(start, end)}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#ddd",
                minHeight: 48,
                borderRadius: 10,
              }}
              variant="light"
              className="d-flex btn-date-range align-items-center"
            >
              <Icon
                color={Colors.darker_gray}
                size={22}
                icon="calendar"
                className="mr-2"
              />
              <p className="m-0 text_e-left">
                {dateInterval.start != ""
                  ? moment(dateInterval.start).format("DD/MM/YYYY")
                  : "Data inicial"}{" "}
                -{" "}
                {dateInterval.end != ""
                  ? moment(dateInterval.end).format("DD/MM/YYYY")
                  : "Data final"}
              </p>
            </Button>
          </DateRangePicker>
        </Row>
      </div>
      <div className="risk-patients card-report mt-4">
        {!loading ? (
          <div>
            <p className="risk-patients-title">
              Pacientes com lesão e comorbidades / estados situacionais
            </p>
            <Row className="hospitalized-patients">
              {mostComorbiditiesCombinationsPercentage &&
              mostComorbiditiesCombinationsPercentage.length > 0 ? (
                <>
                  <Col
                    xs={12}
                    md={5}
                    className="d-flex flex-column justify-content-center"
                  >
                    {mostComorbiditiesCombinationsPercentage
                      .sort((a, b) => b.percentage - a.percentage)
                      .map((item, index) => (
                        <div className="mb-3" key={index}>
                          <div className="analysis-comorbidities-combinations d-flex">
                            <div className="analysis-comorbidities-combinations-percentage">
                              <div
                                style={{
                                  transform: "translateY(-1px)",
                                  width: 16,
                                  height: 16,
                                  borderRadius: 14,
                                  backgroundColor: GetOrderIndexColor(index),
                                  marginLeft: 1,
                                  marginRight: 11,
                                }}
                              />
                              <p>
                                {item.percentage.toString().split(".")[0]}
                                {item.percentage.toString().split(".").length ==
                                2 ? (
                                  <span>
                                    .{item.percentage.toString().split(".")[1]}%
                                  </span>
                                ) : (
                                  <>%</>
                                )}
                              </p>
                            </div>
                            <div className="analysis-comorbidities-combinations-description d-flex justify-content-center align-items-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item.text),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </Col>
                  <Col xs={12} md={7}>
                    <AnalysisCombinationsPie
                      mostComorbiditiesCombinationsPercentage={
                        mostComorbiditiesCombinationsPercentage
                      }
                    />
                  </Col>
                </>
              ) : (
                <EmptyContainer
                  text="Ainda não há dados sobre pacientes com lesão nesse período"
                  iconName="description_1"
                />
              )}
            </Row>
          </div>
        ) : (
          <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <Row>
        <Col sm={12} md={6}>
          <AnalysisComorbiditiesPie
            title={"Comorbidades mais frequentes"}
            loading={loading}
            comorbiditiesPercentages={mostCommonComorbiditiesPercentages}
          />
        </Col>
        <Col sm={12} md={6}>
          <AnalysisComorbiditiesPie
            title={"Estados situacionais mais frequentes"}
            loading={loading}
            comorbiditiesPercentages={mostCommonSituationalStatesPercentages}
          />
        </Col>
      </Row>
    </>
  );
}

export default Analysis;
