import React from "react";

const DotPattern = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "2.75rem",
        height: "0.75rem",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          gap: "3.5px",
        }}
      >
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              backgroundColor: "#5d5a5a",
              margin: "auto",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DotPattern;
