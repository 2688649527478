const INITIAL_STATE = false;

export default function updateHospital(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "UPDATE_HOSPITAL_LIST":
      return !state;
    default:
      return state;
  }
}
