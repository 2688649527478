import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Show_Alert } from "../../../../store/actions/alert";
import api from "../../../../services/api";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import AddHospital from "./AddHospital";
import EditHospital from "./EditHospital";
import ModalEnableDisable from "./ModalEnableDisable";
import Select from "react-select";

var hospitalObj = null;

function Hospitais() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [hospitais, setHospitais] = useState([]);
  const [showAddHospital, setShowAddHospital] = useState(false);
  const [showEditHospital, setShowEditHospital] = useState(false);
  const [showEnableDisable, setShowEnableDisable] = useState(false);
  const [reload, setReload] = useState(false);
  const [statusFilter, setStatusFilter] = useState("active");

  const [nome, setNome] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);
  const [pagination, setPagination] = useState([]);

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  const statusOptions = [
    { value: "all", label: "Todos" },
    { value: "active", label: "Ativos" },
    { value: "inactive", label: "Inativos" },
  ];

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  useEffect(() => {
    if (user.dados.permission_id !== 1) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Você não tem permissão para acessar esta área.",
        })
      );
      return;
    }

    loadHospitais();
  }, [reload]);

  const loadHospitais = async () => {
    setLoading(true);
    try {
      const response = await api.get("/hospitals");
      setHospitais(response.data.filter((h) => h.name !== null));
    } catch (error) {
      console.error("Erro ao carregar hospitais:", error);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Erro ao carregar lista de hospitais.",
        })
      );
    }
    setLoading(false);
  };

  const filterByStatus = (list, status) => {
    switch (status) {
      case "active":
        return list.filter((item) => item.status === 1);
      case "inactive":
        return list.filter((item) => item.status === 0);
      case "all":
      default:
        return list;
    }
  };

  const filteredHospitais = useMemo(
    () => filterByStatus(hospitais, statusFilter),
    [hospitais, statusFilter]
  );

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  return (
    <div>
      {showAddHospital && (
        <AddHospital
          handler_show_modal={setShowAddHospital}
          show_modal={showAddHospital}
          handler_reload={setReload}
          reload={reload}
        />
      )}
      {showEditHospital && (
        <EditHospital
          handler_show_modal={setShowEditHospital}
          show_modal={showEditHospital}
          handler_reload={setReload}
          reload={reload}
          hospitalObj={hospitalObj}
        />
      )}
      {showEnableDisable && (
        <ModalEnableDisable
          handler_show_modal={setShowEnableDisable}
          show_modal={showEnableDisable}
          handler_reload={setReload}
          reload={reload}
          hospitalObj={hospitalObj}
        />
      )}

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name mb-4">Hospitais</h2>
        <button
          onClick={() => setShowAddHospital(true)}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          Novo hospital
        </button>
      </div>

      <Row className="align-items-end">
        <Col xs={12} md={6} lg={4} xl={8}>
          <div>
            <Form.Label className="field-label">Buscar hospital</Form.Label>
            <input
              className="default-input"
              type="text"
              placeholder="Digite o nome do hospital"
              onChange={(e) => {
                setCurrentPage(1);
                setNome(e.target.value);
              }}
              value={nome}
            />
          </div>
        </Col>
        <Col xs={12} md={6} lg={4} xl={4}>
          <Form.Label className="field-label">Status:</Form.Label>
          <Select
            classNamePrefix="react-select"
            options={statusOptions}
            value={statusOptions.find(
              (option) => option.value === statusFilter
            )}
            onChange={(selectedOption) => setStatusFilter(selectedOption.value)}
            styles={{
              control: (base, state) => ({
                ...base,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                  ? Colors.brand_blue
                  : base.borderColor,
                "&:hover": {
                  borderColor: state.isFocused
                    ? Colors.brand_blue
                    : base.borderColor,
                },
              }),
            }}
          />
        </Col>
      </Row>

      {!loading && filteredHospitais.length === 0 && (
        <div className="container-error-default my-3">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && filteredHospitais.length > 0 && (
        <div className="mt-3">
          <Table className="user-table" responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Domínio</th>
                <th>Tempo Sonoro</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredHospitais.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.domain || "-"}</td>
                  <td>{item.time_sound || "-"}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      label=" "
                      checked={item.status === 1}
                      readOnly={true}
                      onClick={() => {
                        hospitalObj = item;
                        setShowEnableDisable(true);
                      }}
                      id={`hospital-panel-item-toggle-${index}`}
                      className={`o-toggle o-toggle-success ${
                        item.status === 1 ? "is-checked" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 10 }}
                        overlay={RenderTooltip("Editar")}
                      >
                        <button
                          className="btn-table-user"
                          onClick={() => {
                            hospitalObj = item;
                            setShowEditHospital(true);
                          }}
                        >
                          <Icon
                            color={Colors.darker_gray}
                            size={22}
                            icon="edit"
                          />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <Row
        className="px-2 w-100"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {filteredHospitais.length} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {pagination.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    item === currentPage
                      ? "btn-nav-numbers active"
                      : "btn-nav-numbers"
                  }
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => SetarDisplay(12)}>
                12 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(24)}>
                24 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(36)}>
                36 resultados
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default Hospitais;
