import moment from "moment";

import {
  MOMENT_DATETIME_FORMAT,
  TIMER_BEHIND_DEFAULT,
  TIMER_PERIOD_DEFAULT,
} from "./Consts";

export const filterSchedules = (scheduleItem, options) => {
  const { date, timeInitial, timeFinal } = options;

  const initial = moment(`${date} ${timeInitial}`, MOMENT_DATETIME_FORMAT);
  const final = moment(`${date} ${timeFinal}`, MOMENT_DATETIME_FORMAT);

  const schedule = moment(
    `${date} ${scheduleItem.schedule}`,
    MOMENT_DATETIME_FORMAT
  );

  return (
    (timeInitial !== undefined && schedule.isSame(initial)) ||
    (timeFinal !== undefined && schedule.isSame(final)) ||
    schedule.isBetween(initial, final)
  );
};

const mapSchedule = (schedule, nextSchedule = null, options) => {
  const timeInitial = moment(schedule.schedule, "HH:mm:ss");
  const timeFinal = nextSchedule
    ? moment(nextSchedule.schedule, "HH:mm:ss").subtract(1, "seconds")
    : moment(options.timeFinal, "HH:mm:ss");

  const timeSize = timeFinal.diff(timeInitial, "seconds");
  const scheduleEnd = moment(timeInitial).add(timeSize, "seconds");
  return {
    ...schedule,
    label:
      timeInitial.format("mm") === "00"
        ? timeInitial.format("HH[h]")
        : timeInitial.format("HH[h]mm"),
    time: timeSize,
    scheduleEnd: scheduleEnd.format("HH:mm:ss"),
  };
};

export const overrideSchedules = (schedules, options) => {
  if (schedules.length === 0) return null;

  const group = [];

  schedules.forEach((item, index) => {
    const nextItem = index + 1;

    if (nextItem === schedules.length) {
      group.push(mapSchedule(item, null, options));
    } else {
      group.push(mapSchedule(item, schedules[nextItem], options));
    }
  });

  return group;
};

export const getSchedules = ({ schedules, options }) => {
  const schedulesFiltered = schedules.filter((schedule) =>
    filterSchedules(schedule, options)
  );

  return overrideSchedules(schedulesFiltered, options);
};

export const defineTimeInitialAndFinalBySchedules = (period, schedules) => {
  const timeDefaultPeriod = TIMER_PERIOD_DEFAULT[period];

  if (!schedules || (schedules && schedules.length === 0))
    return timeDefaultPeriod;
  const date = moment().format("YYYY-MM-DD");

  const schedulesFiltered = getSchedules({
    schedules,
    options: {
      date,
      ...timeDefaultPeriod,
    },
  });

  let timeInitial, timeFinal;

  if (schedulesFiltered.length === 0) return timeDefaultPeriod;

  if (schedulesFiltered.length > 1) {
    if (period === "morning") {
      timeInitial = timeDefaultPeriod.timeInitial;
      const schedulesAftermoonFiltered = getSchedules({
        schedules,
        options: {
          date,
          ...TIMER_PERIOD_DEFAULT["aftermoon"],
        },
      });

      if (schedulesAftermoonFiltered.length === 0) {
        timeFinal = timeDefaultPeriod.timeFinal;
      } else {
        const newTimeFinal = moment(
          schedulesAftermoonFiltered[0].schedule,
          "HH:mm:ss"
        ).subtract(1, "seconds");
        timeFinal = newTimeFinal.format("HH:mm:ss");
      }
    } else {
      timeInitial = schedulesFiltered[0].schedule;
      timeFinal = timeDefaultPeriod.timeFinal;
    }
  } else {
    timeFinal = timeDefaultPeriod.timeFinal;
  }

  return {
    timeInitial,
    timeFinal,
  };
};

export const createStripePattern = (color, paused = false) => {
  const canvas = document.createElement("canvas");
  canvas.width = 12;
  canvas.height = 12;
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 12, 12);

  if (paused) {
    ctx.fillStyle = "#B1B1B1";
    const radius = 2;

    const positions = [
      { x: 6, y: 6 },
      { x: 18, y: 6 },
      { x: 6, y: 18 },
      { x: 18, y: 18 },
    ];

    positions.forEach((pos) => {
      ctx.beginPath();
      ctx.arc(pos.x, pos.y, radius, 0, Math.PI * 2);
      ctx.fill();
    });
  } else {
    ctx.strokeStyle = "black";
    ctx.lineWidth = 0.5;
    ctx.setLineDash([]);

    ctx.beginPath();
    ctx.moveTo(0, 12);
    ctx.lineTo(12, 0);
    ctx.stroke();
  }

  return ctx.createPattern(canvas, "repeat");
};
