import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";

function EditHospital({ ...props }) {
  const {
    handler_show_modal,
    show_modal,
    handler_reload,
    reload,
    hospitalObj,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [nome, setNome] = useState("");
  const [domain, setDomain] = useState("");
  const [timeSound, setTimeSound] = useState(10);
  const [status, setStatus] = useState(1);
  const [alertSoon, setAlertSoon] = useState(1);
  const [alertDelayed, setAlertDelayed] = useState(1);
  const [alertPosition, setAlertPosition] = useState(0);
  const [alertAngle, setAlertAngle] = useState(0);

  useEffect(() => {
    if (hospitalObj) {
      setNome(hospitalObj.name);
      setDomain(hospitalObj.domain || "");
      setTimeSound(hospitalObj.time_sound || 10);
      setStatus(hospitalObj.status || 1);
      setAlertSoon(hospitalObj.alert_soon);
      setAlertDelayed(hospitalObj.alert_delayed);
      setAlertPosition(hospitalObj.alert_position);
      setAlertAngle(hospitalObj.alert_angle);
    }
  }, [hospitalObj]);

  function VerifyFields() {
    return nome !== "";
  }

  async function UpdateHospital() {
    if (!VerifyFields()) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Por favor, preencha os campos obrigatórios.",
        })
      );
      return;
    }

    setLoadingBtn("flex");

    try {
      const data = {
        name: nome,
        domain: domain || null,
        time_sound: parseInt(timeSound),
        status: status,
        alert_soon: alertSoon,
        alert_delayed: alertDelayed,
        alert_position: alertPosition,
        alert_angle: alertAngle,
      };

      await api.put(`/hospitals/${hospitalObj.id}`, data);

      dispatch(
        Show_Alert({
          type: "success",
          msg: "Hospital atualizado com sucesso!",
        })
      );

      handler_show_modal(false);
      handler_reload(!reload);
    } catch (error) {
      console.error("Erro ao atualizar hospital:", error);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Erro ao atualizar hospital. Verifique os dados e tente novamente.",
        })
      );
    }

    setLoadingBtn("none");
  }

  return (
    <div>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={8}>
              <div className="modal-title">Editar hospital</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <div className="mb-4">
              <p className="modal-text mb-2">Nome do hospital*</p>
              <input
                className="default-input"
                type="text"
                placeholder="Digite o nome do hospital"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">Domínio</p>
              <input
                className="default-input"
                type="text"
                placeholder="Digite o domínio"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>

            <Row className="mb-4">
              <Col>
                <p className="modal-text mb-2">Tempo Sonoro (segundos)</p>
                <input
                  className="default-input"
                  type="number"
                  value={timeSound}
                  onChange={(e) => setTimeSound(e.target.value)}
                  min={0}
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p className="modal-text mb-2">Status</p>
                <div className="d-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={status === 1}
                      onClick={() => setStatus(status === 1 ? 0 : 1)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="modal-text ml-3">
                    {status === 1 ? "Ativo" : "Inativo"}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p className="modal-text mb-2">Alerta em Breve</p>
                <div className="d-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={alertSoon === 1}
                      onClick={() => setAlertSoon(alertSoon === 1 ? 0 : 1)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="modal-text ml-3">
                    {alertSoon === 1 ? "Ativo" : "Inativo"}
                  </p>
                </div>
              </Col>
              <Col>
                <p className="modal-text mb-2">Alerta Atrasado</p>
                <div className="d-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={alertDelayed === 1}
                      onClick={() =>
                        setAlertDelayed(alertDelayed === 1 ? 0 : 1)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="modal-text ml-3">
                    {alertDelayed === 1 ? "Ativo" : "Inativo"}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p className="modal-text mb-2">Alerta de Posição</p>
                <div className="d-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={alertPosition === 1}
                      onClick={() =>
                        setAlertPosition(alertPosition === 1 ? 0 : 1)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="modal-text ml-3">
                    {alertPosition === 1 ? "Ativo" : "Inativo"}
                  </p>
                </div>
              </Col>
              <Col>
                <p className="modal-text mb-2">Alerta de Ângulo</p>
                <div className="d-flex align-items-center">
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={alertAngle === 1}
                      onClick={() => setAlertAngle(alertAngle === 1 ? 0 : 1)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="modal-text ml-3">
                    {alertAngle === 1 ? "Ativo" : "Inativo"}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              style={{ borderStyle: "solid" }}
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => handler_show_modal(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={!VerifyFields() || loadingBtn === "flex"}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor: VerifyFields()
                  ? Colors.brand_pink
                  : Colors.lighter_gray,
              }}
              onClick={() => UpdateHospital()}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() ? Colors.white : Colors.dark_gray,
                  display: loadingBtn === "none" ? "flex" : "none",
                }}
              >
                Salvar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditHospital;
