import React, { useEffect, useState } from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import CheckBox from "../../../../components/CheckBox";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import ReactInputMask from "react-input-mask";

function AutomacaoDeEmails() {
  const dispatch = useDispatch();

  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  //mudança decubito
  const [activeMddEmail, setActiveMddEmail] = useState(false);
  //incidencia
  const [activeWoundEmail, setActiveWoundEmail] = useState(false);
  const [activeBatteryEmail, setActiveBatteryEmail] = useState(false);
  const [activeDelayedEmail, setActiveDelayedEmail] = useState(false);

  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState(undefined);
  const [notificationEmailWound, setNotificationEmailWound] = useState("");
  const [notificationsEmailsWound, setNotificationsEmailsWound] =
    useState(undefined);
  const [notificationEmailLowBattery, setNotificationEmailLowBattery] =
    useState("");
  const [
    notificationEmailDelayedPatients,
    setNotificationEmailDelayedPatients,
  ] = useState("");
  const [notificationEmailsLowBattery, setNotificationEmailsLowBattery] =
    useState(undefined);
  const [
    notificationEmailsDelayedPatients,
    setNotificationEmailsDelayedPatients,
  ] = useState(undefined);
  const [emailCount, setEmailCount] = useState({
    lowBatterySensors: 0,
    mdd: 0,
    wound: 0,
    delayedPatients: 0,
  });
  const [emailError, setEmailError] = useState(false);
  const [notificationEmailWoundError, setNotificationEmailWoundError] =
    useState(false);
  const [
    notificationEmailLowBatteryError,
    setNotificationEmailLowBatteryError,
  ] = useState(false);
  const [
    notificationEmailDelayedPatientsError,
    setNotificationEmailDelayedPatientsError,
  ] = useState(false);
  const [delayEmailTime, setDelayEmailTime] = useState(0);
  const [delayedPatientsDelayEmailTime, setDelayedPatientsDelayEmailTime] =
    useState(0);

  const [batteryPercentage, setBatteryPercentage] = useState(0);

  const [delayEmailFrequency, setDelayEmailFrequency] = useState(0);
  const [
    delayLowBatterySensorEmailFrequency,
    setDelayLowBatterySensorEmailFrequency,
  ] = useState("00:00");
  const [delayPatientsEmailFrequency, setDelayPatientsEmailFrequency] =
    useState("00:00");
  const [admittedWoundIsEnable, setAdmittedWoundIsEnable] = useState(null);
  const [sendOnlyOnce, setSendOnlyOnce] = useState(false);
  const [
    lowBatterySensorEmailSendOnlyOnce,
    setLowBatterySensorEmailSendOnlyOnce,
  ] = useState(false);
  const [incidenceWoundIsEnable, setIncidenceWoundIsEnable] = useState(false);
  const [typeTimeSuspend, setTypeTimeSuspend] = useState([
    {
      label: "minutos",
      value: "minutes",
    },
  ]);
  const [typeDelayEmailFrequency, setTypeDelayEmailFrequency] = useState([
    {
      label: "minutos",
      value: "minutes",
    },
  ]);

  const [
    typeLowBatterySensorEmailFrequency,
    setTypeLowBatterySensorEmailFrequency,
  ] = useState([
    {
      label: "dias",
      value: "days",
    },
  ]);

  function toggleDefaultWoundState() {
    setActiveMddEmail(!activeMddEmail);
  }

  function toggleLesaoState() {
    setActiveWoundEmail(!activeWoundEmail);
  }

  function toggleLBatteryState() {
    setActiveBatteryEmail(!activeBatteryEmail);
  }

  function toggleDelayedState() {
    setActiveDelayedEmail(!activeDelayedEmail);
  }

  function isValidEmail(emailToValidate) {
    // Adicionado uma função para validar o e-mail
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(emailToValidate);
  }

  function addEmail() {
    if (email && isValidEmail(email)) {
      if (emails.includes(email)) {
        setEmailError(true);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Digite um e-mail não repetido.",
          })
        );
        return;
      }
      setEmails([...emails, email]);
      setEmail("");
      dispatch(
        Show_Alert({
          type: "success",
          msg: "E-mail cadastrado com sucesso!",
        })
      );
      setEmailError(false);
    } else {
      setEmailError(true);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Digite um e-mail válido.",
        })
      );
    }
  }

  function removeEmail(targetEmail, type) {
    if (type == "lowBatterySensors") {
      let listWithoutEmail = notificationEmailsLowBattery.filter(
        (e) => e !== targetEmail
      );
      setNotificationEmailsLowBattery(listWithoutEmail);
      setEmailCount((prevState) => ({
        ...prevState,
        ["lowBatterySensors"]: listWithoutEmail.length,
      }));
    } else if (type == "delayedPatients") {
      let listWithoutEmail = notificationEmailsDelayedPatients
        ? notificationEmailsDelayedPatients.filter((e) => e !== targetEmail)
        : [];
      setNotificationEmailsDelayedPatients(listWithoutEmail);
      setEmailCount((prevState) => ({
        ...prevState,
        ["delayedPatients"]: listWithoutEmail.length,
      }));
    } else {
      let listWithoutEmail = emails.filter((e) => e !== targetEmail);
      setEmails(listWithoutEmail);
      setEmailCount((prevState) => ({
        ...prevState,
        ["mdd"]: listWithoutEmail.length,
      }));
    }
    deleteEmail(targetEmail, type);
  }

  function deleteEmail(email, type) {
    api.put(
      "/mail-automations/" + (unit.selecionado ? unit.selecionado.id : ""),
      {
        email,
        type,
      }
    );
  }

  function addNotificationEmailWound() {
    if (
      notificationEmailWound &&
      !notificationsEmailsWound.includes(notificationEmailWound) &&
      isValidEmail(notificationEmailWound)
    ) {
      setNotificationsEmailsWound([
        ...notificationsEmailsWound,
        notificationEmailWound,
      ]);
      setNotificationEmailWound("");
      setNotificationEmailWoundError(false);
      dispatch(
        Show_Alert({
          type: "success",
          msg: "E-mail cadastrado com sucesso!",
        })
      );
    } else {
      setNotificationEmailWoundError(true);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Digite um e-mail válido.",
        })
      );
    }
  }

  function addNotificationEmailLowBattery() {
    if (
      notificationEmailLowBattery &&
      !notificationEmailsLowBattery.includes(notificationEmailLowBattery) &&
      isValidEmail(notificationEmailLowBattery)
    ) {
      setEmailCount((prevState) => ({
        ...prevState,
        ["lowBatterySensors"]: [
          ...notificationEmailsLowBattery,
          notificationEmailLowBattery,
        ].length,
      }));
      setNotificationEmailsLowBattery([
        ...notificationEmailsLowBattery,
        notificationEmailLowBattery,
      ]);
      setNotificationEmailLowBattery("");
      setNotificationEmailLowBatteryError(false);
      dispatch(
        Show_Alert({
          type: "success",
          msg: "E-mail cadastrado com sucesso!",
        })
      );
    } else {
      setNotificationEmailLowBatteryError(true);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Digite um e-mail válido.",
        })
      );
    }
  }

  function addNotificationEmailDelayedPatients() {
    if (
      notificationEmailDelayedPatients &&
      !notificationEmailsDelayedPatients.includes(
        notificationEmailDelayedPatients
      ) &&
      isValidEmail(notificationEmailDelayedPatients)
    ) {
      setEmailCount((prevState) => ({
        ...prevState,
        ["delayedPatients"]: [
          ...notificationEmailsDelayedPatients,
          notificationEmailDelayedPatients,
        ].length,
      }));
      setNotificationEmailsDelayedPatients([
        ...notificationEmailsDelayedPatients,
        notificationEmailDelayedPatients,
      ]);
      setNotificationEmailDelayedPatients("");
      setNotificationEmailDelayedPatientsError(false);
      dispatch(
        Show_Alert({
          type: "success",
          msg: "E-mail cadastrado com sucesso!",
        })
      );
    } else {
      setNotificationEmailDelayedPatientsError(true);
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Digite um e-mail válido.",
        })
      );
    }
  }

  function removeNotificationEmailWound(targetEmail) {
    deleteEmail(targetEmail, "wound");
    let listWithoutEmail = notificationsEmailsWound.filter(
      (e) => e !== targetEmail
    );
    setEmailCount((prevState) => ({
      ...prevState,
      ["wound"]: listWithoutEmail.length,
    }));
    setNotificationsEmailsWound(listWithoutEmail);
  }

  useEffect(() => {
    GetEmails();
  }, [unit]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      if (emails !== undefined && emailCount["mdd"] <= emails.length) {
        AddEmails("mdd");
      }
    }, 500);

    setTimer(newTimer);

    return () => {
      clearTimeout(newTimer);
    };
  }, [
    delayEmailTime,
    emails,
    activeMddEmail,
    typeTimeSuspend,
    delayEmailFrequency,
    sendOnlyOnce,
  ]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (
          notificationsEmailsWound !== undefined &&
          emailCount["wound"] <= notificationsEmailsWound.length
        ) {
          AddEmails("wound");
        }
      }, 500);
    }
  }, [
    loading,
    notificationsEmailsWound,
    incidenceWoundIsEnable,
    admittedWoundIsEnable,
    activeWoundEmail,
  ]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (
          notificationEmailsLowBattery !== undefined &&
          emailCount["lowBatterySensors"] <= notificationEmailsLowBattery.length
        ) {
          AddEmails("lowBatterySensors");
        }
      }, 1000);
    }
  }, [
    loading,
    notificationEmailsLowBattery,
    batteryPercentage,
    activeBatteryEmail,
    lowBatterySensorEmailSendOnlyOnce,
  ]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (
          notificationEmailsDelayedPatients !== undefined &&
          emailCount["delayedPatients"] <=
            notificationEmailsDelayedPatients.length
        ) {
          AddEmails("delayedPatients");
        }
      }, 1000);
    }
  }, [
    loading,
    delayLowBatterySensorEmailFrequency,
    delayPatientsEmailFrequency,
    notificationEmailsDelayedPatients,
    delayedPatientsDelayEmailTime,
  ]);

  function GetEmails() {
    setLoading(true);
    api
      .get("/mail-automations/" + unit.selecionado?.id)
      .then((response) => {
        if (response.status == 200) {
          const mddEmailData = response.data.mdd;
          const woundEmailData = response.data.wound;
          const lowBatterySensorsData = response.data.lowBatterySensors;
          const delayedPatients = response.data.delayedPatients;
          setEmailCount({
            mdd: mddEmailData.emails.length,
            wound: woundEmailData.emails.length,
            lowBatterySensors: lowBatterySensorsData.emails.length,
            delayedPatients: delayedPatients.emails.length,
          });
          setActiveMddEmail(mddEmailData.active);
          setEmails(mddEmailData.emails);
          setDelayEmailTime(mddEmailData.delayTime);
          setSendOnlyOnce(mddEmailData.sendOnlyOnce == 0 ? false : true);
          setDelayEmailFrequency(mddEmailData.delayTimeFrequency);
          setActiveWoundEmail(woundEmailData.active);
          setNotificationsEmailsWound(woundEmailData.emails);
          setAdmittedWoundIsEnable(woundEmailData.admission == 1 ? 0 : 1);
          setIncidenceWoundIsEnable(woundEmailData.incidence == 1 ? 0 : 1);
          setActiveBatteryEmail(lowBatterySensorsData.active);
          setActiveDelayedEmail(delayedPatients.active);
          setBatteryPercentage(lowBatterySensorsData.battery);
          setNotificationEmailsLowBattery(lowBatterySensorsData.emails);
          setNotificationEmailsDelayedPatients(delayedPatients.emails);
          setLowBatterySensorEmailSendOnlyOnce(
            lowBatterySensorsData.sendOnlyOnce == 0 ? false : true
          );
          setDelayLowBatterySensorEmailFrequency(
            lowBatterySensorsData.delayTimeFrequency
          );
          setDelayPatientsEmailFrequency(delayedPatients.delayTimeFrequency);
          setDelayedPatientsDelayEmailTime(delayedPatients.delayTime);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function AddEmails(type) {
    if (loading) {
      return;
    }
    api.post(
      "/mail-automations/" + (unit.selecionado ? unit.selecionado.id : ""),
      type === "mdd"
        ? {
            mdd: {
              active: activeMddEmail,
              emails,
              sendOnlyOnce: sendOnlyOnce,
              delayTime:
                !isNaN(delayEmailTime) && typeTimeSuspend.value === "hours"
                  ? delayEmailTime * 60
                  : delayEmailTime,
              delayEmailFrequency:
                !isNaN(delayEmailFrequency) &&
                typeDelayEmailFrequency.value === "hours"
                  ? delayEmailFrequency * 60
                  : delayEmailFrequency,
            },
          }
        : type === "lowBatterySensors"
        ? {
            lowBatterySensors: {
              active: activeBatteryEmail,
              emails: notificationEmailsLowBattery,
              sensorBattery: batteryPercentage,
              sendOnlyOnce: lowBatterySensorEmailSendOnlyOnce,
              delayEmailFrequency: delayLowBatterySensorEmailFrequency,
            },
          }
        : type === "delayedPatients"
        ? {
            delayedPatients: {
              active: activeDelayedEmail,
              emails: notificationEmailsDelayedPatients,
              delayEmailFrequency: delayPatientsEmailFrequency,
              delayTime: parseInt(delayedPatientsDelayEmailTime),
            },
          }
        : {
            wound: {
              active: activeWoundEmail,
              admission: admittedWoundIsEnable === 1 ? 0 : 1,
              incidence: incidenceWoundIsEnable === 1 ? 0 : 1,
              emails: notificationsEmailsWound,
            },
          }
    );
  }

  return (
    <div>
      <h2 className="screen-name mb-4">Automação de e-mails</h2>
      <div className="panel-email-automation">
        <div className="info-automation">
          <div className="d-flex align-items-center">
            <div>
              <p>Enviar e-mail ao atrasar mudança de decúbito</p>
              <p>
                Configure após quanto tempo o sistema deve disparar e-mail
                informando o atraso da mudança de decúbito dos pacientes.
              </p>
            </div>
            <div
              style={{
                marginRight: "15px",
                transform: "translateY(-5px)",
              }}
            >
              <Form.Check
                type="switch"
                label=" "
                disabled={loading}
                checked={activeMddEmail}
                onClick={toggleDefaultWoundState}
                id={`sensor-status`}
                className={`d-inline-block z-index-10 o-toggle o-toggle-success ${
                  activeMddEmail ? "is-checked" : ""
                }`}
              />
            </div>
          </div>

          <div style={{ display: activeMddEmail ? "block" : "none" }}>
            <Row className="input-timers" style={{ marginBottom: "1.3rem" }}>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex decubit-form-configuration"
              >
                <div
                  className="panel-email-automation-email-fields"
                  style={{ width: "100%" }}
                >
                  <Form.Label className="info-automation-subtitle">
                    Enviar e-mail após:
                  </Form.Label>
                  <div className="d-flex">
                    <input
                      type="number"
                      style={{
                        height: "48px",
                        borderRadius: "8px 0px 0px 8px",
                        border: "1px solid #DDDDDD",
                        padding: "12px 16px 12px 16px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: "#FFFFFF",
                      }}
                      className="default-input"
                      placeholder="Digite a quantidade"
                      value={delayEmailTime}
                      onChange={(v) => {
                        let val = parseInt(v.target.value.replace(/^0+/, ""));
                        if (val < 0) {
                          val = 0;
                        }
                        setDelayEmailTime(val);
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "48px",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{ maxHeiht: "100%", fontSize: "15px" }}
                          className="w-100 dropdown-automation-time"
                          id="dropdown-automation-time"
                        >
                          {typeTimeSuspend[0].label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          rootCloseEvent={"click"}
                          className="div-drop-sort"
                        >
                          {typeTimeSuspend.map((item) => {
                            return (
                              <Dropdown.Item
                                style={{
                                  color:
                                    item.value == typeTimeSuspend.value
                                      ? Colors.brand_blue
                                      : Colors.dark_gray,
                                }}
                              >
                                <Icon
                                  icon={item.icon}
                                  size={24}
                                  color={
                                    item.value == typeTimeSuspend.value
                                      ? Colors.brand_blue
                                      : Colors.dark_gray
                                  }
                                  className="mr-3"
                                />
                                {item.label}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  className="panel-email-automation-email-fields"
                  style={{ width: "100%" }}
                >
                  <Form.Label className="info-automation-subtitle">
                    Frequência de reenvio
                  </Form.Label>
                  <div className="d-flex">
                    <input
                      type="number"
                      style={{
                        height: "48px",
                        borderRadius: "8px 0px 0px 8px",
                        border: "1px solid #DDDDDD",
                        padding: "12px 16px 12px 16px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: sendOnlyOnce ? "#EEEEEE" : "#FFFFFF",
                      }}
                      className="default-input"
                      placeholder="Digite a quantidade"
                      value={delayEmailFrequency} // Valor nulo quando o checkbox está marcado
                      onChange={(v) => {
                        if (!sendOnlyOnce) {
                          let val = parseInt(v.target.value.replace(/^0+/, ""));
                          if (val < 0) {
                            val = 0;
                          }
                          setDelayEmailFrequency(val);
                        }
                      }}
                      disabled={sendOnlyOnce} // Input desabilitado quando o checkbox está marcado
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "48px",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{ maxHeiht: "100%", fontSize: "15px" }}
                          className="w-100 dropdown-automation-time"
                          id="dropdown-automation-time"
                        >
                          {typeTimeSuspend[0].label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          rootCloseEvent={"click"}
                          className="div-drop-sort"
                        >
                          {typeTimeSuspend.map((item) => {
                            return (
                              <Dropdown.Item
                                style={{
                                  color:
                                    item.value == typeTimeSuspend.value
                                      ? Colors.brand_blue
                                      : Colors.dark_gray,
                                }}
                              >
                                <Icon
                                  icon={item.icon}
                                  size={24}
                                  color={
                                    item.value == typeTimeSuspend.value
                                      ? Colors.brand_blue
                                      : Colors.dark_gray
                                  }
                                  className="mr-3"
                                />
                                {item.label}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      transform: "translateX(-5px)",
                      marginTop: "5px",
                    }}
                    className="d-flex align-items-center mb-0"
                    onClick={() => {
                      setSendOnlyOnce(!sendOnlyOnce);
                    }}
                  >
                    <CheckBox
                      size="20px"
                      noMargin={true}
                      checked={sendOnlyOnce}
                    />
                    <p className="ml-2 mb-0" style={{ fontSize: 14 }}>
                      Enviar apenas uma vez
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Form.Label className="info-send-email-delay-text">
              Enviar para:
            </Form.Label>
            {emails &&
              emails.map((emailItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between"
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <input
                    type="email"
                    style={{
                      maxWidth: "90%",
                      height: "48px",
                      borderRadius: "8px",
                      border: "1px solid #DDDDDD",
                      padding: "12px 16px 12px 16px",
                      gap: "10px",
                    }}
                    value={emailItem}
                    placeholder="Digite o e-mail"
                    readOnly
                    className="default-input mobile-email-input"
                  />
                  <button
                    className="btn-remove-email"
                    onClick={() => removeEmail(emailItem, "mdd")}
                  >
                    <Icon color={Colors.darkest_gray} size={24} icon="minus" />
                  </button>
                </div>
              ))}
            <div
              className="d-flex justify-content-between"
              style={{
                paddingBottom: "20px",
              }}
            >
              <input
                type="email"
                style={{
                  maxWidth: "90%",
                  height: "48px",
                  borderRadius: "8px",
                  border: emailError
                    ? "1px solid #ff4949"
                    : "1px solid #DDDDDD",
                  padding: "12px 16px 12px 16px",
                  gap: "10px",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite o e-mail"
                className="default-input mobile-email-input"
              />
              <button className="btn-add-email" onClick={addEmail}>
                <Icon color={Colors.darkest_gray} size={24} icon="plus" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="panel-email-automation-2">
        <div className="info-automation">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p>Enviar e-mail sobre notificação de lesão</p>
              <p>
                O sistema irá enviar um e-mail quando houver notificação de
                lesão.
              </p>
            </div>
            <div style={{ marginRight: "15px", transform: "translateY(-5px)" }}>
              <Form.Check
                type="switch"
                label=" "
                disabled={loading}
                checked={activeWoundEmail}
                onClick={toggleLesaoState}
                id={`lesao-status`}
                className={`d-inline-block z-index-10 o-toggle o-toggle-success ${
                  activeWoundEmail ? "is-checked" : ""
                }`}
              />
            </div>
          </div>
          <div style={{ display: activeWoundEmail ? "block" : "none" }}>
            <div
              className="panel-email-automation-email-fields"
              style={{ width: "100%" }}
            >
              <Form.Label className="info-automation-subtitle">
                Tipo de lesão:
              </Form.Label>
              <div className="d-flex"></div>
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center mt-0 mb-0"
                onClick={() => {
                  setAdmittedWoundIsEnable(!admittedWoundIsEnable);
                }}
              >
                <CheckBox
                  size="20px"
                  noMargin={true}
                  checked={!admittedWoundIsEnable}
                />
                <p className="ml-2 mb-0" style={{ fontSize: 14 }}>
                  Lesão admitida
                </p>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="d-flex align-items-center mt-1 mb-4"
                onClick={() => {
                  setIncidenceWoundIsEnable(!incidenceWoundIsEnable);
                }}
              >
                <CheckBox
                  size="20px"
                  noMargin={true}
                  checked={!incidenceWoundIsEnable}
                />
                <p className="ml-2 mb-0" style={{ fontSize: 14 }}>
                  Lesão Incidida
                </p>
              </div>
            </div>

            <Form.Label className="info-send-email-notification-text">
              Enviar para:
            </Form.Label>
            {notificationsEmailsWound &&
              notificationsEmailsWound.map((emailItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between"
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <input
                    type="email"
                    style={{
                      maxWidth: "90%",
                      height: "48px",
                      borderRadius: "8px",
                      border: "1px solid #DDDDDD",
                      padding: "12px 16px 12px 16px",
                      gap: "10px",
                    }}
                    value={emailItem}
                    readOnly // Torne o campo somente leitura, já que é uma lista
                    className="default-input  mobile-email-input"
                  />
                  <button
                    className="btn-remove-email"
                    onClick={() => removeNotificationEmailWound(emailItem)}
                  >
                    <Icon color={Colors.darkest_gray} size={24} icon="minus" />
                  </button>
                </div>
              ))}
            <div
              className="d-flex justify-content-between"
              style={{
                paddingBottom: "20px",
              }}
            >
              <input
                type="email"
                style={{
                  maxWidth: "90%",
                  height: "48px",
                  borderRadius: "8px",
                  border: notificationEmailWoundError
                    ? "1px solid #ff4949"
                    : "1px solid #DDDDDD",
                  padding: "12px 16px 12px 16px",
                  gap: "10px",
                }}
                placeholder="Digite o e-mail"
                value={notificationEmailWound}
                onChange={(e) => {
                  setNotificationEmailWound(e.target.value);
                  setNotificationEmailWoundError(false); // Resetar o erro ao digitar
                }}
                className="default-input mobile-email-input"
              />
              <button
                className="d-flex add-email align-items-center btn-add-email"
                onClick={addNotificationEmailWound} // Chamar função ao clicar
              >
                <Icon color={Colors.darkest_gray} size={24} icon="plus" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="panel-email-automation-battery">
        <div className="info-automation">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="teste">
                Enviar e-mail sobre sensores com bateria baixa
              </p>
              <p>
                O sistema irá enviar um e-mail quando houver sensor com bateria
                baixa.
              </p>
            </div>
            <div
              style={{
                marginRight: "15px",
                transform: "translateY(-5px)",
              }}
            >
              <Form.Check
                type="switch"
                label=" "
                disabled={loading}
                checked={activeBatteryEmail}
                onClick={toggleLBatteryState}
                id="toggle-sensor-bateria-baixa"
                className={`d-inline-block z-index-10 o-toggle o-toggle-success ${
                  activeBatteryEmail ? "is-checked" : ""
                }`}
              />
            </div>
          </div>

          <div style={{ display: activeBatteryEmail ? "block" : "none" }}>
            <Row className="input-timers" style={{ marginBottom: "1.3rem" }}>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex decubit-form-configuration"
              >
                <div
                  className="panel-email-automation-email-fields"
                  style={{ width: "100%" }}
                >
                  <Form.Label className="info-automation-subtitle">
                    Porcentagem da bateria
                  </Form.Label>
                  <div className="d-flex">
                    <input
                      type="number"
                      style={{
                        height: "48px",
                        borderRadius: "8px 0px 0px 8px",
                        border: "1px solid #DDDDDD",
                        padding: "12px 16px 12px 16px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                      }}
                      className="default-input"
                      placeholder="Digite a quantidade"
                      value={batteryPercentage}
                      onChange={(v) => {
                        let val = parseInt(v.target.value.replace(/^0+/, ""));
                        if (val < 0) {
                          val = 0;
                        } else if (val > 100) {
                          val = 100;
                        }
                        setBatteryPercentage(val);
                      }}
                    />

                    <div>
                      <Dropdown className="w-100 dropdown-item-percentage">
                        %
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  className="panel-email-automation-email-fields"
                  style={{ width: "100%" }}
                >
                  <Form.Label className="info-automation-subtitle">
                    Horário de envio
                  </Form.Label>
                  <div className="d-flex">
                    <ReactInputMask
                      mask={"99:99"}
                      maskChar={null}
                      style={{
                        height: "48px",
                        borderRadius: "8px 0px 0px 8px",
                        border: "1px solid #DDDDDD",
                        padding: "12px 16px 12px 16px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: lowBatterySensorEmailSendOnlyOnce
                          ? "#EEEEEE"
                          : "#FFFFFF",
                      }}
                      className="default-input"
                      placeholder="Digite a quantidade"
                      value={delayLowBatterySensorEmailFrequency}
                      onChange={(e) => {
                        if (!lowBatterySensorEmailSendOnlyOnce) {
                          let value = e.target.value;
                          const [hours, minutes] = value.split(":").map(Number);

                          if (
                            hours < 0 ||
                            hours > 23 ||
                            minutes < 0 ||
                            minutes > 59
                          ) {
                            value = "00:00";
                          }

                          setDelayLowBatterySensorEmailFrequency(value);
                        }
                      }}
                      disabled={lowBatterySensorEmailSendOnlyOnce}
                    />

                    <div>
                      <Dropdown className="w-100 dropdown-item-percentage">
                        HH:mm
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      transform: "translateX(-5px)",
                      marginTop: "5px",
                    }}
                    className="d-flex align-items-center mb-0"
                    onClick={() => {
                      setLowBatterySensorEmailSendOnlyOnce(
                        !lowBatterySensorEmailSendOnlyOnce
                      );
                    }}
                  >
                    <CheckBox
                      size="20px"
                      noMargin={true}
                      checked={lowBatterySensorEmailSendOnlyOnce}
                    />
                    <p className="ml-2 mb-0" style={{ fontSize: 14 }}>
                      Enviar apenas uma vez
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Form.Label className="info-send-email-delay-text">
              Enviar para:
            </Form.Label>
            {notificationEmailsLowBattery &&
              notificationEmailsLowBattery.map((emailItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between"
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <input
                    type="email"
                    style={{
                      maxWidth: "90%",
                      height: "48px",
                      borderRadius: "8px",
                      border: "1px solid #DDDDDD",
                      padding: "12px 16px 12px 16px",
                      gap: "10px",
                    }}
                    value={emailItem}
                    placeholder="Digite o e-mail"
                    readOnly
                    className="default-input mobile-email-input"
                  />
                  <button
                    className="btn-remove-email"
                    onClick={() => removeEmail(emailItem, "lowBatterySensors")}
                  >
                    <Icon color={Colors.darkest_gray} size={24} icon="minus" />
                  </button>
                </div>
              ))}
            <div
              className="d-flex justify-content-between"
              style={{
                paddingBottom: "20px",
              }}
            >
              <input
                type="email"
                style={{
                  maxWidth: "90%",
                  height: "48px",
                  borderRadius: "8px",
                  border: notificationEmailLowBatteryError
                    ? "1px solid #ff4949"
                    : "1px solid #DDDDDD",
                  padding: "12px 16px 12px 16px",
                  gap: "10px",
                }}
                value={notificationEmailLowBattery}
                onChange={(e) => setNotificationEmailLowBattery(e.target.value)}
                placeholder="Digite o e-mail"
                className="default-input mobile-email-input"
              />
              <button
                className="btn-add-email"
                onClick={addNotificationEmailLowBattery}
              >
                <Icon color={Colors.darkest_gray} size={24} icon="plus" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-email-automation-battery">
        <div className="info-automation">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="teste title-automation">
                Enviar e-mail com relatório de atrasos na mudança de decúbito
              </p>
              <p>
                O sistema irá enviar um e-mail com o relatório sobre atrasos na
                mudança de decúbito
              </p>
            </div>
            <div
              style={{
                marginRight: "15px",
                transform: "translateY(-5px)",
              }}
            >
              <Form.Check
                type="switch"
                label=" "
                disabled={loading}
                checked={activeDelayedEmail}
                onClick={toggleDelayedState}
                id="toggle-delayed-email"
                className={`d-inline-block z-index-10 o-toggle o-toggle-success ${
                  activeDelayedEmail ? "is-checked" : ""
                }`}
              />
            </div>
          </div>

          <div style={{ display: activeDelayedEmail ? "block" : "none" }}>
            <Row className="input-timers" style={{ marginBottom: "1.3rem" }}>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  className="panel-email-automation-email-fields"
                  style={{ width: "100%" }}
                >
                  <Form.Label className="info-automation-subtitle">
                    Enviar e-mail às:
                  </Form.Label>
                  <div className="d-flex">
                    <ReactInputMask
                      mask={"99:99"}
                      maskChar={null}
                      style={{
                        height: "48px",
                        borderRadius: "8px 0px 0px 8px",
                        border: "1px solid #DDDDDD",
                        padding: "12px 16px 12px 16px",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: "#FFFFFF",
                      }}
                      className="default-input"
                      placeholder="Digite a quantidade"
                      value={delayPatientsEmailFrequency}
                      onChange={(e) => {
                        let value = e.target.value;
                        setDelayPatientsEmailFrequency(value);
                      }}
                      onBlur={() => {
                        const [hoursStr, minutesStr] =
                          delayPatientsEmailFrequency.split(":");
                        const hours = Number(hoursStr);
                        const minutes = Number(minutesStr);

                        if (
                          !isNaN(hours) &&
                          !isNaN(minutes) &&
                          (hours < 0 ||
                            hours > 23 ||
                            minutes < 0 ||
                            minutes > 59)
                        ) {
                          setDelayPatientsEmailFrequency("00:00");
                        }
                      }}
                    />

                    <div>
                      <Dropdown className="w-100 dropdown-item-percentage">
                        HH:mm
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="d-flex align-items-end decubit-form-configuration"
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Form.Label className="field-label">
                    Tempo de tolerância{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-top">
                          Tempo máximo que uma mudança de decúbito realizada
                          pode atrasar sem ser considerado um atraso.
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <span
                        style={{
                          transform: "translate(8px, -2px)",
                          position: "absolute",
                        }}
                      >
                        <Icon icon="info" size={14} color={"#454545"}></Icon>
                      </span>
                    </OverlayTrigger>
                  </Form.Label>
                  <input
                    type="number"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: "#ffff",
                    }}
                    className="default-input"
                    placeholder="Digite o período"
                    value={delayedPatientsDelayEmailTime}
                    onChange={(v) => {
                      let val =
                        parseInt(v.target.value.replace(/^0+/, "")) || 1;
                      val = Math.min(Math.max(val, 1), 999);
                      setDelayedPatientsDelayEmailTime(val);
                    }}
                  />
                </div>

                <div style={{ maxWidth: "100px", width: "33%" }}>
                  <Dropdown className="mdd-dropdown-time">
                    <Dropdown.Toggle
                      style={{ maxHeiht: 37, fontSize: "15px" }}
                      className="w-100 dropdown-dashboard-time"
                      id="dropdown-mdd-time"
                    >
                      minutos
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      rootCloseEvent={"click"}
                      className="div-drop-sort"
                    >
                      {["minutes"].map((item) => {
                        return (
                          <Dropdown.Item
                            style={{
                              color: Colors.brand_blue,
                            }}
                            onClick={() => {}}
                          >
                            minutos
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>

            <Form.Label className="info-send-email-delay-text">
              Enviar para:
            </Form.Label>
            {notificationEmailsDelayedPatients &&
              notificationEmailsDelayedPatients.map((emailItem, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between"
                  style={{
                    paddingBottom: "20px",
                  }}
                >
                  <input
                    type="email"
                    style={{
                      maxWidth: "90%",
                      height: "48px",
                      borderRadius: "8px",
                      border: "1px solid #DDDDDD",
                      padding: "12px 16px 12px 16px",
                      gap: "10px",
                    }}
                    value={emailItem}
                    placeholder="Digite o e-mail"
                    readOnly
                    className="default-input mobile-email-input"
                  />
                  <button
                    className="btn-remove-email"
                    onClick={() => removeEmail(emailItem, "delayedPatients")}
                  >
                    <Icon color={Colors.darkest_gray} size={24} icon="minus" />
                  </button>
                </div>
              ))}
            <div
              className="d-flex justify-content-between"
              style={{
                paddingBottom: "20px",
              }}
            >
              <input
                type="email"
                style={{
                  maxWidth: "90%",
                  height: "48px",
                  borderRadius: "8px",
                  border: notificationEmailDelayedPatientsError
                    ? "1px solid #ff4949"
                    : "1px solid #DDDDDD",
                  padding: "12px 16px 12px 16px",
                  gap: "10px",
                }}
                value={notificationEmailDelayedPatients}
                onChange={(e) =>
                  setNotificationEmailDelayedPatients(e.target.value)
                }
                placeholder="Digite o e-mail"
                className="default-input mobile-email-input"
              />
              <button
                className="btn-add-email"
                onClick={addNotificationEmailDelayedPatients}
              >
                <Icon color={Colors.darkest_gray} size={24} icon="plus" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomacaoDeEmails;
